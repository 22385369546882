<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Payments Customer Request
      </div>
    </div>
    <div class="modal__body page-request">
      <div class="box box_payments">
      <div class="content">
        <div class="block">
          <div class="ttl">
            <Bank2 class="img" />
            <div class="text">Bank account (wire)</div>
          </div>
          <div class="main">
            <div class="box box_left box_tmblers box_arrow-r">
              <div class="box_ttl">Payment methods you need</div>
              <div class="box_list" :key="paymentMethodsListKey">
                <div v-for="(item, key) in paymentMethods" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.name" @changeState="paymentMethodsChangeState(item)" />
                </div>
              </div>
              <div class="arrow-r"><Arrow /></div>
            </div>
            <div class="main_center">
              <div class="box box_prcs">
                <div class="block">
                  <div class="ttl">Minimum ammount ($)</div>
                  <div class="val">{{ number_format(request.ba_minimum_ammount, 0, '.', ' ') }}</div>
                </div>
                <div class="block">
                  <div class="ttl">Maximum ammount ($)</div>
                  <div class="val">{{ number_format(request.ba_maximum_ammount, 0, '.', ' ') }}</div>
                </div>
                <div class="block">
                  <div class="ttl">Purpose of payment</div>
                  <div class="val val-text">{{ request.ba_payment_purpose }}</div>
                </div>
              </div>
              <div class="box box_prcs">
                <div class="block">
                  <div class="ttl">Incoming fee</div>
                  <div class="val">{{ number_format(request.ba_incoming_fee, 2, '.', ' ') }}%</div>
                </div>
                <div class="block">
                  <div class="ttl">Outgoing fee</div>
                  <div class="val">{{ number_format(request.ba_outgoing_fee, 2, '.', ' ') }}%</div>
                </div>
                <div class="block">
                  <div class="ttl">Recall reason</div>
                  <div class="val val-text">{{ request.ba_recall_reason }}</div>
                </div>
              </div>
            </div>
            <div class="box box_right box_tmblers box_arrow-l">
              <div class="box_ttl">List of Account currencies</div>
              <div class="box_list box_list-2" :key="accountCurrenciesIndex">
                <div v-for="(item, key) in accountCurrencies" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.code" @changeState="accountCurrenciesChangeState(item)" />
                </div>
              </div>
              <div v-if="showOtherAccountCurrency">
                <div class="box_ttl">Type of Account</div>
                <input v-model="request.other_account_currency" type="text" class="inpt inpt_acc" placeholder="Enter type">
              </div>
              <div class="arrow-r"><Arrow /></div>
            </div>
          </div>
        </div>
        <div class="block">
          <div class="ttl">
            <CreditCards class="img" />
            <div class="text">Merchant account</div>
          </div>
          <div class="main">
            <div class="box box_left box_tmblers box_arrow-r">
              <div class="box_ttl">Type of cards you need</div>
              <div class="box_list box_list-2" :key="cardTypesIndex">
                <div v-for="(item, key) in cardTypes" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.name" @changeState="cardTypesChangeState(item)" />
                </div>
              </div>
              <div class="arrow-r"><Arrow /></div>
            </div>
            <div class="main_center">
              <div class="box box_prcs">
                <div class="block">
                  <div class="ttl">Minimum ammount ($)</div>
                  <div class="val">{{ number_format(request.ma_minimum_ammount, 0, '.', ' ') }}</div>
                </div>
                <div class="block">
                  <div class="ttl">Maximum ammount ($)</div>
                  <div class="val">{{ number_format(request.ma_maximum_ammount, 0, '.', ' ') }}</div>
                </div>
                <div class="block">
                  <div class="ttl">Type of your MC code needed</div>
                  <div class="val val-text">{{ request.ma_mc_code_type }}</div>
                </div>
              </div>
              <div class="box box_prcs">
                <div class="block">
                  <div class="ttl">Incoming fee</div>
                  <div class="val">{{ number_format(request.ma_incoming_fee, 2, '.', ' ') }}%</div>
                </div>
                <div class="block">
                  <div class="ttl">Outgoing fee</div>
                  <div class="val">{{ number_format(request.ma_outgoing_fee, 2, '.', ' ') }}%</div>
                </div>
                <div class="block">
                  <div class="ttl">Chargeback reason</div>
                  <div class="val val-text">{{ request.ma_chargeback_reason }}</div>
                </div>
              </div>
            </div>
            <div class="box box_right box_tmblers box_arrow-l">
              <div class="box_ttl">List of card processing currencies</div>
              <div class="box_list box_list-2" :key="processingCurrenciesIndex">
                <div v-for="(item, key) in processingCurrencies" :key="key" class="list_item">
                  <Tmblr :active="item.active" :text="item.code" @changeState="processingCurrenciesChangeState(item)" />
                </div>
              </div>
              <input v-model="request.other_processing_currency" v-if="showOtherProcessingCurrency" type="text" class="inpt inpt_procCurr" placeholder="Enter type">
              <div class="arrow-r"><Arrow /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'
import Bank2 from './../img/Bank2.vue'
import Tmblr from './../elem/Tmblr.vue'
import Arrow from './../img/Arrow.vue'
import CreditCards from './../img/CreditCards.vue'

export default {
  components: {
    Close,
    Bank2,
    Tmblr,
    Arrow,
    CreditCards,
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      message: '',
      error: '',
      userPaymentMethods: [],
      userAccountCurrencies: [],
      userCardTypes: [],
      userProcessingCurrencies: [],
      paymentMethods: [],
      paymentMethodsListKey: 0,
      accountCurrencies: [],
      accountCurrenciesIndex:0,
      cardTypes: [],
      cardTypesIndex:0,
      processingCurrencies: [],
      processingCurrenciesIndex:0,
      showOtherAccountCurrency: false,
      showOtherProcessingCurrency: false,
      request: {
        ba_incoming_currencies: [],
        ba_incoming_fee: '',
        ba_maximum_ammount: '',
        ba_minimum_ammount: '',
        ba_outgoing_fee: '',
        ba_payment_methods: [],
        ba_payment_purpose: '',
        ba_recall_reason: '',
        ma_card_types: [],
        ma_chargeback_reason: '',
        ma_incoming_fee: '',
        ma_maximum_ammount: '',
        ma_mc_code_type: '',
        ma_minimum_ammount: '',
        ma_outgoing_fee: '',
        ma_processing_currencies: [],
        user_id: null
      },
    };
  },
  created () {
    this.getAccountCurrencies()
    this.getProcessingCurrencies()
    this.getPaymentMethods()
    this.getCardTypes()
    this.getRequest()
  },
  methods: {
    submit () {
      const that = this
      let userPaymentMethodIds = this.paymentMethods.filter(function(el) { return el.active })
      userPaymentMethodIds = userPaymentMethodIds.map(el => el.id)
      let accountCurrenciesIds = this.accountCurrencies.filter(function(el) { return el.active })
      accountCurrenciesIds = accountCurrenciesIds.map(el => el.id)
      let cardTypesIds = this.cardTypes.filter(function(el) { return el.active })
      cardTypesIds = cardTypesIds.map(el => el.id)
      let processingCurrenciesIds = this.processingCurrencies.filter(function(el) { return el.active })
      processingCurrenciesIds = processingCurrenciesIds.map(el => el.id)
      axios.post('admin/request/update/' + this.options.id, {
        bank_account_payment_method_ids: userPaymentMethodIds,
        bank_account_incoming_currency_ids: accountCurrenciesIds,
        merchant_account_card_type_ids: cardTypesIds,
        merchant_account_processing_currency_ids: processingCurrenciesIds,
        other_account_currency: this.request.other_account_currency,
        other_processing_currency: this.request.other_processing_currency,
      })
        .then(function (response) {
          console.log("Your request data was successfully changed")
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    paymentMethodsChangeState (item) {
      const index = this.paymentMethods.findIndex(paymentMethod => {
          return (item.id === paymentMethod.id)
      })
      this.$set(this.paymentMethods[index], 'active', !item.active)
      this.paymentMethodsListKey++
      this.submit()
    },
    accountCurrenciesChangeState (item) {
      const index = this.accountCurrencies.findIndex(accountCurrency => {
          return (item.id === accountCurrency.id)
      })
      this.$set(this.accountCurrencies[index], 'active', !item.active)
      if (this.accountCurrencies[index].code == 'Other') {
        if (this.accountCurrencies[index].active) {
          this.showOtherAccountCurrency = true
        } else {
          this.showOtherAccountCurrency = false
          this.request.other_account_currency = ''
        }
      }
      this.accountCurrenciesIndex++
      this.submit()
    },
    cardTypesChangeState (item) {
      const index = this.cardTypes.findIndex(cardType => {
          return (item.id === cardType.id)
      })
      this.$set(this.cardTypes[index], 'active', !item.active)
      this.cardTypesIndex++
      this.submit()
    },
    processingCurrenciesChangeState (item) {
      const index = this.processingCurrencies.findIndex(processingCurrency => {
          return (item.id === processingCurrency.id)
      })
      this.$set(this.processingCurrencies[index], 'active', !item.active)
      if (this.processingCurrencies[index].code == 'Other') {
        if (this.processingCurrencies[index].active) {
          this.showOtherProcessingCurrency = true
        } else {
          this.showOtherProcessingCurrency = false
          this.request.other_processing_currency = ''
        }
      }
      this.processingCurrenciesIndex++
      this.submit()
    },
    getAccountCurrencies () {
      const that = this
      axios.get('admin/data/currencies')
        .then(function (response) {
          that.accountCurrencies = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getProcessingCurrencies () {
      const that = this
      axios.get('admin/data/currencies')
        .then(function (response) {
          that.processingCurrencies = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getPaymentMethods () {
      const that = this
      axios.get('admin/data/payment-methods')
        .then(function (response) {
          that.paymentMethods = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getCardTypes () {
      const that = this
      axios.get('admin/data/card-types')
        .then(function (response) {
          that.cardTypes = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getRequest () {
      const that = this
      axios.get('admin/request/show/' + this.options.id)
        .then(function (response) {
          that.request = response.data.data
          if (that.request && that.request.user) {
            that.userPaymentMethods = that.request.user.bank_account_payment_methods
            that.userAccountCurrencies = that.request.user.bank_account_incoming_currencies
            that.userCardTypes = that.request.user.merchant_account_card_types
            that.userProcessingCurrencies = that.request.user.merchant_account_processing_currencies
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    close() {
      this.$emit("close");
    }
  },
  computed: {
    isAllPaymentDataFetched() {
      if (this.paymentMethods.length > 0 && this.userPaymentMethods.length >0) {
        return true
      }
      return false
    },
    isAllAccountCurrenciesFetched() {
      if (this.accountCurrencies.length > 0 && this.userAccountCurrencies.length >0) {
        return true
      }
      return false
    },
    isAllCardTypesFetched() {
      if (this.cardTypes.length > 0 && this.userCardTypes.length >0) {
        return true
      }
      return false
    },
    isAllProcessingCurrenciesFetched() {
      if (this.cardTypes.length > 0 && this.userCardTypes.length >0) {
        return true
      }
      return false
    }
  },
  watch: {
    isAllPaymentDataFetched: {
      handler: function(val) {
        for (let i = 0; i < this.paymentMethods.length; i++) {
          this.paymentMethods[i].active = false
          for (let z = 0; z < this.userPaymentMethods.length; z++) {
            if (this.paymentMethods[i].id === this.userPaymentMethods[z].id) {
              this.paymentMethods[i].active = true
            }
          }
        }
      },
      deep: true
    },
    isAllAccountCurrenciesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.accountCurrencies.length; i++) {
          this.accountCurrencies[i].active = false
          for (let z = 0; z < this.userAccountCurrencies.length; z++) {
            if (this.accountCurrencies[i].id === this.userAccountCurrencies[z].id) {
              this.accountCurrencies[i].active = true
              if (this.accountCurrencies[i].code == 'Other') {
                this.showOtherAccountCurrency = true
              }
            }
          }
        }
      },
      deep: true
    },
    isAllCardTypesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.cardTypes.length; i++) {
          this.cardTypes[i].active = false
          for (let z = 0; z < this.userCardTypes.length; z++) {
            if (this.cardTypes[i].id === this.userCardTypes[z].id) {
              this.cardTypes[i].active = true
            }
          }
        }
      },
      deep: true
    },
    isAllProcessingCurrenciesFetched: {
      handler: function(val) {
        for (let i = 0; i < this.processingCurrencies.length; i++) {
          this.processingCurrencies[i].active = false
          for (let z = 0; z < this.userProcessingCurrencies.length; z++) {
            if (this.processingCurrencies[i].id === this.userProcessingCurrencies[z].id) {
              this.processingCurrencies[i].active = true
              if (this.processingCurrencies[i].code == 'Other') {
                this.showOtherProcessingCurrency = true
              }
            }
          }
        }
      },
      deep: true
    },
    'request.other_account_currency': {
      handler: function(val) {
        this.submit()
      },
      deep: true
    },
    'request.other_processing_currency': {
      handler: function(val) {
        this.submit()
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.modal__wrapper {
  background: #272E35;
  color: #fff;

  .modal__body {
    font-size: 10px;
    margin-bottom: 20px;
  }

  .tmblr {
    margin: 3px 0px;
  }
}

.page-request {
  .box {
    background: #272E35;

    &_payments {
      .content {
        padding-top: 0;

        .block {
          padding-bottom: 1rem;

          .ttl {
            text-align: center;

            svg.img {
              width: 2.5rem;
              height: 2.5rem;
            }
          }

          .main {
            display: flex;
            align-items: center;
            justify-content: center;

            &_center {
              width: 45%;
            }

            .box {
              &_tmblers {
                padding: 1.5rem 2.5rem;
                background-color: #22252B;

                .box_list-2 {
                  display: flex;
                  flex-wrap: wrap;
                  padding-bottom: .75rem;

                  .list_item {
                    min-width: 45%;

                    @media (max-width: 1600px) {
                      width: 100%;
                    }
                  }
                }

                .row {
                  display: flex;

                  .box_list {
                    margin-right: 2rem;
                    margin-bottom: 1rem;
                  }
                }

                .box_ttl {
                  font-size: 14px;
                  padding-bottom: .5rem;
                }

                .inpt {
                  width: 100%;
                }
              }

              &_left {
                width: 25%;

                @media (max-width: 1440px) {
                  padding-left: 1.5rem;
                }
              }

              &_right {
                width: 25%;

                @media (max-width: 1440px) {
                  padding-right: 1.5rem;
                }
              }

              &_arrow-r {
                position: relative;
                margin-right: 3rem;

                .arrow-r {
                  top: 50%;
                  right: 0;
                  transform: translate(50%, -50%);
                }
              }

              &_arrow-l {
                position: relative;
                margin-left: 3rem;

                .arrow-r {
                  top: 50%;
                  left: 0;
                  transform: translate(-50%, -50%);
                }
              }

              &_prcs {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                // background-color: rgba(19, 180, 151, 0.1);
                padding: 1rem 0;

                > :nth-child(2) {
                  // border-left: 2px solid rgba(19, 180, 151, 0.1);
                  // border-right: 2px solid rgba(19, 180, 151, 0.1);
                }

                .block {
                  width: calc(33% - .5rem);
                  padding: .25rem 1.5rem;

                  .ttl {
                    font-size: 10px;
                    text-align: left;
                    color: rgba(255, 255, 255, 0.5);
                  }

                  .val {
                    font-size: 1.5rem;

                    &-text {
                      font-size: 1rem;
                    }
                  }
                }
              }
            }
          }
        }

        .footer {
          display: flex;
          justify-content: flex-end;
          padding-bottom: 1rem;

          .btn {
            &_submit {
              padding: 1.25rem 3.5rem;
              margin: .25rem .75rem;
              background: var(--gr_new);
              box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
              border-radius: 6px;
            }
          }
        }
      }
    }
  }

  .arrow-r {
    position: absolute;
    width: 3rem;
    height: 3rem;
    background: var(--gr_new);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep svg {
      width: 65%;
      height: 65%;

      path {
        fill: #ffffff;
      }
    }
  }

  .inpt {
    &_procCurr {
      width: 50%;
      margin-left: 45%;
    }
  }
}
</style>
