<template>
	<overlay-scrollbars class="page page-offers" ref="os">
		<div class="section-title-wrap">
			<h2 class="page-details__title section-title">
				<span class="bc-first">Chats With Banks</span>
			</h2>
		</div>

		<div class="chat">
			<div class="sidebar chat-scrollbar">
				<div class="sidebar__header">
					<div class="sidebar__title">Chats With Banks</div>
					<div class="sidebar__add" @click="showAddParticipantModal = true"><img src="/img/chat-plus.svg" alt="">
					</div>
				</div>
				<div class="sidebar__main">
					<div class="sidebar__search search">
						<SearchIcon class="search__icon" />
						<input type="text" class="search__input" placeholder="Enter name or email" v-model="roomName">
					</div>
					<div class="sidebar__main-title">Active Chat</div>
					<div class="sidebar__list">
						<div @click="selectRoom(room.id)" v-for="(room, index) in filteredRooms" :key="index">
							<div v-if="room.type == 1" class="chat-item" :class="{ 'chat-item_active': room.active }">
								<div class="chat-item__left">
									<div class="avatar">
										<img :src="room.interlocutor.logo" alt="">
									</div>
									<div class="chat-item__info">
										<div class="chat-item__name">{{ room.interlocutor.name }}</div>
										<div class="chat-item__last-message" v-if="room.messages[room.messages.length - 1]">
											{{ room.messages[room.messages.length - 1].message_text }}
										</div>
									</div>
								</div>
								<div class="chat-item__right">
									<div class="last-message" v-if="room.latest_message">{{ room.latest_message.created_at |
										moment("MM/DD HH:mm") }}</div>
									<div class="unread-messages" v-if="room.unread_messages">{{ room.unread_messages }}
									</div>
								</div>
							</div>
							<div v-else class="chat-item" :class="{ 'chat-item_active': room.active }">
								{{ room.name }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="main">
				<div class="chat-header" v-if="rooms.length > 0 && activeRoom">
					<div class="participant">
            <div v-if="activeRoom.interlocutor" class="avatar">
              <img :src="activeRoom.interlocutor.logo">
            </div>
            <div class="participant__info">
              <div class="participant__name">
                {{ activeRoom.interlocutor ? activeRoom.interlocutor.name : activeRoom.name }}
              </div>
              <div class="participant__online">Last seen recently</div>
            </div>
          </div>

					<div class="chat-header__btns" v-if="roomSettingsAccess">
						<div class="chat-header__btn">
							<img src="/img/chat-arrows.svg" alt="">
						</div>
						<div class="chat-header__btn" @click="chatSettingsEditing = !chatSettingsEditing">
							<EditIcon />
						</div>
						<div class="chat-header__btn" @click="chatDeleteModalOpened = !chatDeleteModalOpened">
							<TableRemoveItemIcon />
						</div>
					</div>
				</div>

				<div class="chat-main">
					<div class="messages" v-if="rooms.length > 0 && activeRoom" ref="chatMessagesScrolls">
						<div class="message" v-for="(message, index) in activeRoom.messages" :key="index">
							<div class="message__left">
								<div class="avatar"><img v-if="message.user" :src="message.user.logo" alt=""></div>
								<div class="message__info">
									<div class="message__username" v-if="message.user">{{ message.user.full_name }}</div>
									<div class="message__text">{{ message.message_text }}</div>
									<div class="message__text" v-for="(doc, id) in message.documents" :key="id">
										<a class="document-link" target="_blank" v-if="doc.ready || !doc.loading"
											:href="backendUrl + '/storage/chat/documents/' + doc.file">{{
												doc.original_name
											}}</a>
										<div v-else>{{ doc.original_name }}</div>
										<k-progress v-if="doc.loading" status="success" type="line"
											:percent="doc.loadingPercent" :color="['#05737A']" :show-text="false">
										</k-progress>
									</div>
								</div>
							</div>
							<div class="message__right">
								<div class="seen"></div>
								<div class="time">{{ message.created_at | moment("MM/DD HH:mm") }}</div>
							</div>
						</div>
					</div>
					<div class="no-messages" v-else>
						No messages
					</div>
				</div>
				<div class="chat-controls" v-if="rooms.length > 0 && activeRoom">
					<div class="attach" @click="openFilesSelection"><img src="/img/attach.svg" alt="">
						<input @change="onFilesChange" ref="filesInput" accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pdf"
							class="files-input hide" type="file" name="filefield" multiple="multiple">
					</div>
					<div class="message-input">
						<input type="text" v-model="message" placeholder="Type message...">
						<div class="emoji"><img src="/img/emoji.svg" alt=""></div>
					</div>
					<div class="btn-send" @click="sendMessage"><img src="/img/send.svg" alt=""></div>
				</div>
			</div>
		</div>
		<Dialog header="Select User" modal :style="{ width: '400px' }" :visible.sync="showAddParticipantModal"
			class="select-participant-modal">
			<div class="select-participant-content">
				<div class="search">
					<SearchIcon class="search__icon" />
					<input type="text" class="search__input" placeholder="Enter name or email" v-model="nameOrEmail">
				</div>
				<div v-if="selectParticipantsError.length > 0" class="invalid-feedback">{{ selectParticipantsError }}</div>

				<div class="modal-participants">
					<div class="modal-participant" v-for="participant in filteredParticipants"
						@click="selectParticipant(participant.id)">
						<div class="avatar"><img :src="participant.logo" alt=""> </div>
						<div class="modal-participant__username">{{ participant.name }}</div>
					</div>
				</div>
			</div>
		</Dialog>
		<Dialog header="Confirm chat deleting" modal :style="{ width: '400px' }" :visible.sync="chatDeleteModalOpened"
			class="confirm-delete-modal">
			<div class="confirm-delete-btns">
				<div class="btn-gradient btn-delete" @click="deleteRoom">Delete</div>
				<div class="btn-gradient" @click="chatDeleteModalOpened = false">Cancel</div>
			</div>
		</Dialog>
		<!-- <Dialog modal :style="{ width: '500px' }" :visible.sync="chatSettingsEditing" class="edit-settings-modal">
			<div class="edit-settings-content">
				<div class="form__group input-group">
					<InputText class="input-dark" type="number" id="progress" v-model="email"
						placeholder="Enter progress" />
				</div>
				<div class="form__group input-group">
					<InputText class="input-dark" id="description" v-model="email" placeholder="Enter description" />
				</div>
				<div class="btn btn-gradient" style="margin: 0 60px;">Apply</div>
			</div>
		</Dialog> -->
	</overlay-scrollbars>
</template>

<script>
import Vue from 'vue'
Vue.use(require('vue-moment'))
import { mapMutations, mapActions, mapGetters } from 'vuex';
import KProgress from 'k-progress';
Vue.component('k-progress', KProgress);
import EditIcon from '@/components/icons/EditIcon.vue';
import SearchIcon from '@/components/icons/SearchIcon.vue'
import Dialog from "primevue/dialog";
import TableRemoveItemIcon from '@/components/icons/TableRemoveItemIcon.vue';

export default {
	components: {
		EditIcon,
		SearchIcon,
		Dialog,
		TableRemoveItemIcon
	},
	data() {
		return {
			message: null,
			rooms: [],
			filteredRooms: [],
			chats: [],
			activeRoom: null,
			chatMessages: [],
			backendUrl: process.env.VUE_APP_BACKEND_URL,
			chatParticipants: [],
			defaultPersonalManager: null,
			offerShowProgressForm: false,
			offerProgress: '',
			offerProgressDescription: '',
			chatSettingsEditing: false,
			showAddParticipantModal: false,
			nameOrEmail: '',
			filteredParticipants: [],
			selectParticipantsError: '',
			newChatRoom: null,
			chatSettingsEditing: false,
			roomName: '',
			chatDeleteModalOpened: false
		}
	},
	methods: {
		...mapMutations({
			setMessagesCount: 'SET_MESSAGES_COUNT',
			updateChatsMethod: 'UPDATE_CHATS',
			decrementMessageCount: 'DECREMENT_MESSAGE_COUNT'
		}),
		...mapActions({
			setCountMessagesRead: 'setCountMessagesRead',
		}),
		openFilesSelection() {
			this.$refs.filesInput.click()
		},
		editChatSettings() {
			console.log('edit chat settings modal')
		},
		async selectParticipant(id) {
			axios.post('chat/create-room', {
				participants_ids: [id],
			})
				.then(response => {
					if (response.data.success) {
						this.newChatRoom = response.data.room;
						this.$noty.success('You successfully created chat')
					}
					this.showAddParticipantModal = false;
				})
				.catch(errors => {
					console.dir(errors)
				})
		},
		onFilesChange(e) {
			const files = e.target.files || e.dataTransfer.files;

			if (!files.length)
				return

			axios.post('chat/message', {
				message: '',
				room_id: this.activeRoom.id,
				with_documents: true,
			})
				.then(({ data }) => {
					if (data.success && this.activeRoom) {
						this.sendFiles(files, data.message)
					}
					this.message = ''
				})
		},
		sendFiles(files, message) {
			const docs = []
			for (let i = 0; i < files.length; i++) {
				docs.push({
					id: message.id,
					original_name: files[i].name,
					loading: true,
					loadingPercent: 0
				})
				files[i].id = message.id
			}
			message.documents = docs
			for (let i = 0; i < this.rooms.length; i++) {
				if (this.rooms[i].id == this.activeRoom.id) {
					this.rooms[i].messages.push(message)
				}
			}

			files.forEach(file => {
				this.uploadFile(file, message)
			})
			const that = this
			setTimeout(function () {
				that.$refs.chatMessagesScrolls.scroll({ top: that.$refs.chatMessagesScrolls.scrollHeight });
			}, 700)
		},
		uploadFile(file, message) {
			const that = this
			const config = {
				headers: { "Content-Type": "multipart/form-data" },
				onUploadProgress: function (progressEvent) {
					var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
					for (let i = 0; i < that.rooms.length; i++) {
						if (that.rooms[i].id == that.activeRoom.id) {
							for (let k = 0; k < that.rooms[i].messages.length; k++) {
								if (that.rooms[i].messages[k].id == file.id) {
									for (let n = 0; n < that.rooms[i].messages[k].documents.length; n++) {
										if (that.rooms[i].messages[k].documents[n].original_name == file.name) {
											that.rooms[i].messages[k].documents[n].loadingPercent = percentCompleted
										}
									}
								}
							}
						}
					}
				}
			}
			let data = new FormData()
			data.append('file', file)

			axios.post('chat/document/' + message.id, data, config)
				.then(({ data }) => {
					if (data.success) {
						for (let i = 0; i < that.rooms.length; i++) {
							if (that.rooms[i].id == that.activeRoom.id) {
								for (let k = 0; k < that.rooms[i].messages.length; k++) {
									if (that.rooms[i].messages[k].id == message.id) {
										for (let n = 0; n < that.rooms[i].messages[k].documents.length; n++) {
											if (that.rooms[i].messages[k].documents[n].original_name == file.name) {
												that.rooms[i].messages[k].documents[n].loading = false
												that.rooms[i].messages[k].documents[n].ready = true
												that.rooms[i].messages[k].documents[n].file = data.document.file
											}
										}
									}
								}
							}
						}
					}
				})
				.catch(err => console.log(err))
		},
		isUserMessage(message) {
			return message.user_id == this.user.id
		},
		getUserMessageLogin(message) {
			if (message.user_id != this.user.id) {
				return message.user.full_name
			}
			return ''
		},
		sendMessage() {
			if (this.message && this.message.length > 1 && this.activeRoom) {
				axios.post('chat/message', {
					message: this.message,
					room_id: this.activeRoom.id,
				})
					.then(({ data }) => {
						if (data.success && this.activeRoom) {
							for (let i = 0; i < this.rooms.length; i++) {
								if (this.rooms[i].id == this.activeRoom.id) {
									this.rooms[i].messages.push(data.message)
								}
							}
						}
						this.message = ''
						const that = this
						setTimeout(function () {
							that.$refs.chatMessagesScrolls.scroll({ top: that.$refs.chatMessagesScrolls.scrollHeight });
						}, 200)
					})
					.catch((error) => console.log(error))
			}
		},
		selectRoom(roomId) {
			for (let i = 0; i < this.rooms.length; i++) {
				if (+this.rooms[i].id == +roomId) {
					this.activeRoom = this.rooms[i]
					const el = this.rooms[i]
					el.active = true
					this.offerProgress = el.offer_progress
					this.offerProgressDescription = el.offer_progress_description

					this.rooms.splice(i, 1, el)
				} else {
					const el = this.rooms[i]
					el.active = false

					this.rooms.splice(i, 1, el)
				}
			}


			this.setRoomMessagesRead(roomId)

			const that = this
			setTimeout(function () {
				that.$refs.chatMessagesScrolls.scroll({ top: that.$refs.chatMessagesScrolls.scrollHeight });
			}, 2500)

			this.setCountRoomMessagesRead(this.activeRoom.id)
		},
		proccessServerRoom(room) {
			if (room.type == 1) {
				const anotherUser = room.participants[0].user.id != this.$store.state.user?.id ? room.participants[0] : room.participants[1]
				room.interlocutor = {
					name: anotherUser.user.full_name,
					logo: anotherUser.user.logo
				}
			} else {
				room.name = room.name ? room.name : room.public_name
				room.hasName = room.name ? true : false
			}
			room.unread_messages = room.messages.length - room.user_read_statuses_count
			room.active = false
			return room
		},
		getRooms() {
			const that = this
			this.rooms = []
			axios.get('chat/rooms').then(({ data }) => {
				for (let i = 0; i < data.length; i++) {
					data[i] = this.proccessServerRoom(data[i])
				}
				if (data[0]) {
					data[0].active = true
					data[0].unread_messages = 0
					that.activeRoom = data[0]
					that.setRoomMessagesRead(data[0].id)
					that.offerProgress = data[0].offer_progress
					that.offerProgressDescription = data[0].offer_progress_description
					setTimeout(() => {
						that.$refs.chatMessagesScrolls.scroll({ top: that.$refs.chatMessagesScrolls.scrollHeight });
					}, 700)
				}
				this.rooms = this.filteredRooms = data;
				if (this.$route.params && this.$route.params.chatId) {
					this.selectRoom(this.$route.params.chatId)
				}
			})
		},
		setRoomMessagesRead(roomId) {
			for (let i = 0; i < this.rooms.length; i++) {
				if (this.rooms[i].id == roomId) {
					if (this.rooms[i].unread_messages) {
						this.decrementMessageCount(this.rooms[i].unread_messages)
					}
					this.rooms[i].unread_messages = 0
				}
			}
			axios.post('chat/room-messages-read/' + roomId)
				.then(({ data }) => {
					if (data.success) {
						this.$store.commit('SET_MESSAGES_COUNT', 0)
					}
				})
				.catch((error) => console.log(error))
		},
		setCountRoomMessagesRead(roomId) {
			axios.post('chat/count-room-messages-read/' + roomId)
				.then(({ data }) => {
					if (data.success) {
						this.$store.commit('SET_MESSAGES_COUNT', 0)
					}
				})
				.catch((error) => console.log(error))
		},
		addChatParticipants() {
			this.$store.commit('setModal', {
				template: 'select-chat-participants',
				options: {
					participants: this.chatParticipants
				}
			})
		},
		getChatParticipants() {
			axios.get('chat/participants')
				.then(({ data }) => {
					this.chatParticipants = this.filteredParticipants = data
				})
				.catch((error) => console.log(error))
		},
		getPersonalMagager() {
			axios.get('chat/default-personal-manager')
				.then(({ data }) => {
					this.defaultPersonalManager = data
				})
				.catch((error) => console.log(error))
		},
		saveOfferDescription() {
			axios.post('chat/offer-progress/' + this.activeRoom.id, {
				offer_progress: this.offerProgress,
				offer_progress_description: this.offerProgressDescription,
			})
				.then(({ data }) => {
					for (let i = 0; i < this.rooms.length; i++) {
						if (this.rooms[i].id == this.activeRoom.id) {
							this.rooms[i].offer_progress = data.offer_progress
							this.rooms[i].offer_progress_description = data.offer_progress_description
						}
					}
					this.offerShowProgressForm = false
				})
				.catch((error) => console.log(error))
		},
		selectOfferUser() {
			if (this.activeRoom) {
				this.$store.commit('setModal', {
					template: 'select-chat-offer-user',
					options: {
						participants: this.activeRoom.participants
					}
				})
			}
		},
		getRole(message) {
			if (message.user && message.user.usertype == 'admin') {
				return 'admin'
			} else if (message.user && message.user.is_provider == 1) {
				return 'provider'
			}
			return 'user'
		},
		deleteRoom() {
			axios.delete('chat/delete-room/' + this.activeRoom.id)
				.then(res => {
					if(res.data.status === 'success'){
						this.getRooms();
						this.chatDeleteModalOpened = false;
					}
				})
				.catch(error => console.dir(error))
		}
	},
	async created() {
		this.getRooms()
		this.getChatParticipants()
		this.setCountMessagesRead()
		this.getPersonalMagager()
	},
	computed: {
		...mapGetters({
			newMessage: 'getNewMessage',
			chatRoomApplyOfferObj: 'getChatRoomApplyOfferObj',
			offerUser: 'getChatOfferUser',
			chatOffer: 'getChatOffer',
		}),
		user() {
			return this.$store.state.user
		},
		roomSettingsAccess() {
			let hasAccess = false
			const user = this.$store.state.user
			if (user) {
				if (user.is_admin) {
					hasAccess = true
				} else if (user.is_provider) {
					hasAccess = true
				}
				if (this.defaultPersonalManager && this.defaultPersonalManager.id == user.id) {
					hasAccess = true
				}
			}
			return hasAccess
		}
	},
	watch: {
		offerProgress: function (val) {
			if (val < 0) {
				this.offerProgress = 0
			} else if (val > 100) {
				this.offerProgress = val.substring(0, 2)
			}
		},
		chatRoomApplyOfferObj(chatRoomApplyOfferObj) {
			if (chatRoomApplyOfferObj) {
				for (let i = 0; i < this.rooms.length; i++) {
					if (this.rooms[i].id == this.chatRoomApplyOfferObj.room_id) {
						const room = this.rooms[i]
						room.apply_offer = chatRoomApplyOfferObj.apply_offer
						Vue.set(this.rooms, i, room)
					}
				}
				this.activeRoom.apply_offer = chatRoomApplyOfferObj.apply_offer
			}
		},
		offerUser(offerUser) {
			this.$store.commit('setModal', {
				template: 'chat-offer',
				options: {
					offerUser: offerUser.user
				}
			})
		},
		nameOrEmail(nameOrEmail) {
			if (nameOrEmail.length > 0) {
				this.filteredParticipants = []
				this.chatParticipants.forEach(participant => {
					let include = false
					if (participant.company_full_name && participant.company_full_name.toLowerCase().includes(nameOrEmail.toLowerCase())) {
						include = true
					}
					if (participant.email && participant.email.toLowerCase().includes(nameOrEmail.toLowerCase())) {
						include = true
					}
					if (include) {
						this.filteredParticipants.push(participant)
					}
				})
			} else {
				this.filteredParticipants = this.chatParticipants
			}
		},
		roomName(roomName) {
			if (roomName.length > 0) {
				this.filteredRooms = []
				this.rooms.forEach(room => {
					let include = false

					if (room.name && room.name.toLowerCase().includes(roomName.toLowerCase())) {
						include = true
					}

					if (room.interlocutor && room.interlocutor.name && room.interlocutor.name.toLowerCase().includes(roomName.toLowerCase())) {
						include = true
					}
					if (include) {
						this.filteredRooms.push(room)
					}
				})
			} else {
				this.filteredRooms = this.rooms;
			}
		},
		chatOffer(chatOffer) {
			axios.post('chat/offer/' + this.activeRoom.id, {
				id: chatOffer.id,
				type: chatOffer.type,
			})
				.then(({ data }) => {
					this.activeRoom.offerable_id = data.offerable_id
					this.activeRoom.offerable_type = data.offerable_type
					this.activeRoom.offerable = data.offerable
					for (let i = 0; i < this.rooms.length; i++) {
						if (this.rooms[i].id == this.activeRoom.id) {
							const room = this.rooms[i]
							room.offerable_id = data.offerable_id
							room.offerable_type = data.offerable_type
							room.offerable = data.offerable
							Vue.set(this.rooms, i, room)
						}
					}
				})
				.catch((error) => console.log(error))
		},
		newChatRoom(newChatRoom) {
			if (newChatRoom) {
				newChatRoom = this.proccessServerRoom(newChatRoom)
				for (let i = 0; i < this.rooms.length; i++) {
					this.rooms[i].active = false
				}
				newChatRoom.active = true
				this.rooms.unshift(newChatRoom)
				this.selectRoom(newChatRoom)
			}
		},
		newMessage(newMessage) {
			if (newMessage) {
				for (let i = 0; i < this.rooms.length; i++) {
					if (this.rooms[i].id == newMessage.message.room_id) {

						let noSameMessage = true
						this.rooms[i].messages.forEach(message => {
							if (message.id == newMessage.message.id) {
								if (newMessage.message.documents.length > 0) {
									message.documents.forEach(document => {
										if (newMessage.message.documents) {
											newMessage.message.documents.forEach(newDocument => {
												if (document.id == newDocument.id) {
													noSameMessage = false
												}
											})
										}
									})
								} else {
									noSameMessage = false
								}

							}
						})

						if (noSameMessage) {
							const message = newMessage.message

							message.user = {
								id: newMessage.sender_id,
								full_name: newMessage.sender_login,
								logo: newMessage.sender_logo,
							}
							this.rooms[i].messages.push(message)
							if (this.rooms[i].id != this.activeRoom.id) {
								this.rooms[i].unread_messages++
							} else {
								const that = this
								setTimeout(function () {
									that.setRoomMessagesRead(that.activeRoom.id)
									that.setCountRoomMessagesRead(that.activeRoom.id)
									that.decrementMessageCount()
									that.$refs.chatMessagesScrolls.scroll({ top: that.$refs.chatMessagesScrolls.scrollHeight });
								}, 700)
							}
						}
					}
				}

				this.rooms.sort(function (x, y) { return x.id == newMessage.message.room_id ? -1 : y.id == newMessage.message.room_id ? 1 : 0; });
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.confirm-delete-btns {
	display: flex;

	.btn-delete {
		margin-right: 10px;
		background: #AA0000;
	}
}

.edit-settings-content {
	display: flex;
	flex-direction: column;
	align-items: center;

	.form__group {
		width: 100%;
		margin-bottom: 10px;

		input {
			width: 100%;
		}
	}
}

.document-link {
	text-decoration: underline;
}

.avatar {
	margin-right: 10px;

	img {
		border-radius: 50%;
		width: 56px;
		height: 56px;
		max-width: initial
	}
}

.modal-participants {
	margin-top: 20px;

	.modal-participant {
		display: flex;
		align-items: center;
		cursor: pointer;
		margin-top: 15px;

		&__username {
			margin-left: 10px;
			font-weight: 18px;
		}
	}
}



.chat {
	display: flex;
	border-radius: 30px;
	overflow: hidden;
	gap: 15px;

	.sidebar {
		width: 30%;
		box-shadow: 0px 24px 32px 0px #32324714;
		background-color: #1C1C24;
		overflow-y: scroll;
		scrollbar-width: thin;

		&__search {
			margin: 20px 13px;

			input {
				border: none;
				background-color: #000;
				padding: 18px 52px;
			}
		}

		&__add {
			cursor: pointer;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px 25px 10px 15px;
			background-image: url('/img/purple-bg.png');
			background-size: cover;
			background-size: 200%;
		}

		&__title {
			font-size: 18px;
			font-weight: bold;
		}

		&__main-title {
			margin-top: 20px;
			font-weight: 500;
			padding-left: 15px;
		}

		&__list {
			margin-top: 20px;
			max-height: 500px;
		}

		.chat-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 25px 15px 15px;
			border-top: 1px solid #CFEBFF;
			cursor: pointer;

			&__left {
				display: flex;
				align-items: center;
			}

			&__right {
				text-align: right;

				.last-message {
					margin-bottom: 5px;
					color: #9B9B9B;
				}

				.unread-messages {
					display: inline-block;
					font-weight: bold;
					padding: 3px 13px;
					background-color: #1C31F0;
					border-radius: 30px;
				}
			}

			&_active {
				background-color: #0B0B0B;
				position: relative;
				overflow: hidden;

				&::after {
					content: '';
					position: absolute;
					right: -2px;
					top: 39%;
					background-image: url('/img/chat-active-triangle.svg');
					background-repeat: no-repeat;
					background-size: contain;
					width: 20px;
					height: 25px;
				}
			}

			&__name {
				font-weight: bold;
				margin-bottom: 5px;
			}

			&__last-message {
				color: #9B9B9B;
				padding-right: 10px;
				max-width: 240px;
				overflow: hidden;
			}
		}

	}

	.main {
		width: 70%;
		box-shadow: 0px 24px 32px 0px #32324714;
		background-color: #1C1C24;

		.chat-header {
			background-image: url('/img/purple-bg.png');
			background-size: cover;
			display: flex;
			justify-content: space-between;
			padding: 10px 20px;
			align-items: center;

			.participant {
				display: flex;

				&__online {
					color: #969696;
				}

				&__name {
					color: #fff;
					font-weight: bold;
					font-size: 18px;
				}
			}

			&__btns {
				display: flex;
			}

			&__btn {
				background: radial-gradient(90.63% 90.63% at 32.03% 9.37%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.53) 100%)
					/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
					,
					linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
				width: 50px;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				margin-left: 20px;
			}
		}

		.chat-main {
			min-height: 500px;

			.messages {
				padding: 40px 20px;
				overflow-y: scroll;
				scrollbar-width: thin;
				height: 500px;
			}

			.message {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 17px;

				&__left {
					display: flex;
					align-items: flex-start;
					max-width: 80%;
				}

				&__text {
					overflow-wrap: anywhere;
				}

				&__username {
					color: #1C31F0;
					margin-bottom: 5px;
					font-weight: bold;
				}
			}
		}

		.chat-controls {
			border-top: 1px solid #3C3C3C;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 15px;

			.attach {
				width: 53px;
				height: 45px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #1C31F0;
				border-radius: 46px;
				cursor: pointer;
			}

			.message-input {
				display: flex;
				align-items: center;
				background-color: #FFFFFF29;
				padding: 13px 15px;
				border-radius: 46px;
				height: 45px;
				width: 90%;
				justify-content: space-between;
				margin: 0 7px;

				.emoji {
					cursor: pointer;
				}

				input {
					background-color: transparent;
					width: 100%;
					margin-right: 10px;
					border: none;
					color: #fff;

					&:focus {
						outline: none;
					}
				}
			}

			.btn-send {
				background: linear-gradient(90.86deg, #1C31F0 -2.12%, #931CF0 87.62%);
				width: 53px;
				height: 45px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 46px;
				cursor: pointer;
			}
		}
	}
}

.search {
	position: relative;
	margin-top: 20px;

	&-match {
		font-size: 18px;
		padding: 5px;
		font-weight: 700;
		letter-spacing: 0.7px;
		border-bottom: 1px solid #E5E5E5
	}

	&__icon {
		position: absolute;
		top: 50%;
		left: 1rem;
		transform: translateY(-50%);
		width: 24px;
		height: 24px;
		pointer-events: none;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&__input {
		width: 100%;
		min-width: 100%;
		height: 100%;
		padding: .5rem .75rem .5rem 56px;
		color: #fff;
		border-radius: 10rem;
		background-color: #1C1C24;

		&::placeholder {
			color: #696969;
		}
	}
}
</style>