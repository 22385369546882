<template>
  <overlay-scrollbars class="page page-balance">
    <div class="box box_myBalance">
      <div class="header">
        <div class="title">My balance</div>
        <div class="block">
          <Stat class="img" />
          <div class="block_content">
            <div class="subtxt">Balance status</div>
            <div class="txt">{{ number_format(myBalance.status, 2, '.', ' ') }}€</div>
          </div>
        </div>
        <div class="block">
          <Graph class="img" />
          <div class="block_content">
            <div class="subtxt">Total request cost</div>
            <div class="txt">{{ number_format(myBalance.trc, 2, '.', ' ') }}€</div>
          </div>
        </div>
        <div class="block">
          <CircleLoad class="img" />
          <div class="block_content">
            <div class="subtxt">Must be covered cost <ArrowFlat class="high" /></div>
            <div class="txt">{{ number_format(myBalance.mbcc, 2, '.', ' ') }}€</div>
          </div>
        </div>
        <div class="block block-paymentDeadline">
          Payment Deadline:
          <span class="val">{{ myBalance.paymentDeadline.days }}</span> <span class="desc">days</span>
          <span class="val">{{ myBalance.paymentDeadline.hours }}</span> <span class="desc">hours</span>
        </div>
      </div>
    </div>
    <div class="box box_appList">
      <div class="header">
        <div class="title">YOUR APPLICATION LIST</div>
      </div>
      <div class="content">
        <div class="block">
          <div class="hdr">
            <Bank2 class="img" /><div class="ttl">Bank account</div>
          </div>
          <Table :options="bankAcc" />
        </div>
        <div class="block">
          <div class="hdr">
            <CreditCards class="img" /><div class="ttl">Merchant account</div>
          </div>
          <Table :options="merchantAcc" />
        </div>
        <div class="block">
          <div class="hdr">
            <Bank2 class="img" /><div class="ttl">Legal Service</div>
          </div>
          <Table :options="legalService" />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Info from './../components/elem/Info.vue'
import Table from './../components/elem/Table.vue'

import Stat from './../components/img/Stat.vue'
import Graph from './../components/img/Graph.vue'
import CircleLoad from './../components/img/CircleLoad.vue'
import ArrowFlat from './../components/img/ArrowFlat.vue'
import Bank2 from './../components/img/Bank2.vue'
import CreditCards from './../components/img/CreditCards.vue'
import Close from './../components/img/Close.vue'

export default {
  components: {
    Info,
    Table,
    Stat,
    Graph,
    CircleLoad,
    ArrowFlat,
    Bank2,
    CreditCards,
    Close,
  },
  data: () => ({
    myBalance: {
      status: 9000,
      trc: 16500,
      mbcc: 7500,
      paymentDeadline: {
        days: 5,
        hours: 23
      }
    },
    bankAcc: {
      colsWidth: ['3%', '10%', '10%', '12%', '13%', '15%', '15%', '15%', '5%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name of Bank', info: null },
        { ttl: 'Country', info: null },
        { ttl: 'Type of Account', info: null },
        { ttl: 'Open/Integration fee', info: null },
        { ttl: 'Incoming fee', info: null },
        { ttl: 'Outgoing fee', info: null },
        { ttl: 'Onboarding time deadline', info: null },
        { ttl: '', info: null },
      ],
      body: [
        [1, 'EMI', 'UK', 'SEPA', '2 500.00€', '0.35%+15.00€', '10.00€', { type: 'deadline_lite', days: 5, hours: 23 }, { type: 'del' }],
        [2, 'EMI', 'FR', 'SEPA', '3 000.00€', 'Free', '5.00€', { type: 'deadline_lite', days: 2, hours: 20 }, { type: 'del' }],
        [3, 'EMI', 'IT', 'SWIFT', '3 500.00€', '0.2%, min 10.00€, max 100.00€', '0.5%, min 15.00€, max 350.00€', { type: 'deadline_lite', days: 2, hours: '02' }, { type: 'del' }],
      ],
      footer: {
        type: 'Total Price',
        val: '9 000.00€'
      }
    },
    merchantAcc: {
      colsWidth: ['3%', '10%', '10%', '12%', '13%', '15%', '15%', '15%', '5%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name of Bank', info: null },
        { ttl: 'Country', info: null },
        { ttl: 'Type of Zone', info: null },
        { ttl: 'Open/Integration fee', info: null },
        { ttl: 'Incoming fee', info: null },
        { ttl: 'Outgoing fee', info: null },
        { ttl: 'Onboarding time deadline', info: null },
        { ttl: '', info: null },
      ],
      body: [
        [1, 'PCI DSS', 'UK', 'Visa, Master', '3 000.00€', '3.2%', '0.5%', { type: 'deadline_lite', days: 14, hours: 12 }, { type: 'del' }],
        [2, 'PCI DSS', 'FR', 'Visa, Master', '2 000.00€', '4%', '0.3%', { type: 'deadline_lite', days: 14, hours: 12 }, { type: 'del' }],
      ],
      footer: {
        type: 'Total Price',
        val: '5 000.00€'
      }
    },
    legalService: {
      colsWidth: ['3%', '13%', '15%', '15%', '32%', '15%', '5%'],
      header: [
        { ttl: '#', info: null },
        { ttl: 'Name of Legal Co.', info: null },
        { ttl: 'Type of Services', info: null },
        { ttl: 'Price', info: null },
        { ttl: 'Description of Benefit', info: null },
        { ttl: 'Service time deadline', info: null },
        { ttl: '', info: null },
      ],
      body: [
        [1, 'EMI', 'SEPA', '2 500.00€', 'Benefit, benefit, benefit, benefit, benefit, benefit, benefit, benefit, benefit, benefit', { type: 'deadline_lite', days: 14, hours: 12 }, { type: 'del' }],
      ],
      footer: {
        type: 'Total Price',
        val: '2 500.00€'
      }
    }
  }),
}
</script>

<style lang="scss" scoped>
.page-balance {
  .box {
    &_myBalance {
      background: var(--gr_new);

      .header {
        display: flex;
        align-items: center;
        background-image: url('/img/group_61.png');
        background-position: 102% -0.5rem;
        background-repeat: no-repeat;
        background-size: auto;
        border-radius: 14px;

        .block {
          display: flex;
          padding: .25rem 1rem;

          svg.img {
            width: 1.5rem;
            height: 1.5rem;
            margin-top: 10px;
          }

          &_content {
            padding: 0 .75rem;

            .subtxt {
              position: relative;
              font-size: 10px;

              ::v-deep svg {
                width: .75rem;
                height: .75rem;
                margin: 0 4px;

                &.high {
                  transform: rotate(-90deg);
                  path {
                    fill: #B03636;
                  }
                }
              }
            }

            .txt {
              display: flex;
              align-items: center;
              font-size: 1.25rem;
              line-height: 1.1;

              ::v-deep svg {
                width: .75rem;
                height: .75rem;
                margin: 6px;

                &.high {
                  transform: rotate(-90deg);
                  path {
                    fill: #B03636;
                  }
                }
              }
            }
          }

          &-paymentDeadline {
            padding: 0.25rem 1.5rem;
            margin: 0 .75rem 0 2rem;
            background: #272E35;
            border-radius: 6px;
            align-items: baseline;

            .val {
              margin: 0 .25rem 0 .5rem;
              font-size: 22px;
              color: #1C31F0;
            }

            .desc {
              margin: 0 .25rem 0 -0.125rem;
              font-size: 14px;
            }
          }
        }
      }
    }

    &_appList {
      .content {
        padding: 0 0 1rem;

        .block {
          margin-bottom: 1.5rem;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: .5rem;

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
            }
          }
        }
      }
    }
  }
}
</style>