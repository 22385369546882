var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('overlay-scrollbars',{staticClass:"page page-offer shapes-bg"},[_c('div',{staticClass:"section-title-wrap"},[_c('h2',{staticClass:"page-details__title section-title"},[_c('span',{staticClass:"bc-first"},[_vm._v("Applied Offers")])])]),_c('div',{staticClass:"box box-bg-shapes"},[_c('div',{staticClass:"box-header"},[_c('div',{staticClass:"pills"},_vm._l((_vm.pills.data),function(item,index){return _c('div',{key:index,staticClass:"pill",class:{ 'pill_active': _vm.pills.active == item.slug },on:{"click":function($event){return _vm.changePill(item.slug)}}},[_vm._v(_vm._s(item.text))])}),0)]),_c('div',{staticClass:"box-body"},[_c('div',{staticClass:"main-table offers-table"},[(_vm.pills.active == 'bankOffers')?_c('DataTable',{attrs:{"value":_vm.bankOffers.body}},[_c('Column',{attrs:{"field":"serial","header":"#"}}),_c('Column',{attrs:{"field":"user","header":"Name","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
var field = ref.field;
return [_c('div',{staticClass:"user-details-link",on:{"click":function($event){return _vm.gotoUserDetails(data[field].id)}}},[_vm._v(" "+_vm._s(data[field].full_name)+" ")])]}}],null,false,2099816328)}),_c('Column',{attrs:{"field":"acc_type","header":"Type of Account","sortable":""}}),_c('Column',{attrs:{"field":"oif","header":"Open/Integration fee","sortable":""}}),_c('Column',{attrs:{"field":"incoming_fee","header":"Incoming fee","sortable":""}}),_c('Column',{attrs:{"field":"outgoing_fee","header":"Outgoing fee","sortable":""}}),_c('Column',{attrs:{"field":"onboarding","header":"Onboarding time","sortable":""}}),_c('Column',{attrs:{"header":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"col-dark"},[_c('div',{staticClass:"table-col-icon",on:{"click":function($event){return _vm.bankOffersApply(data)}}},[_c('Check',{staticStyle:{"width":"30px","height":"30px"}})],1)])]}}],null,false,2166616571)})],1):_vm._e(),(_vm.pills.active == 'legalServices')?_c('DataTable',{attrs:{"value":_vm.legalServices.body}},[_c('Column',{attrs:{"field":"serial","header":"#"}}),_c('Column',{attrs:{"field":"user","header":"Name","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
var field = ref.field;
return [_c('div',{staticClass:"user-details-link",on:{"click":function($event){return _vm.gotoUserDetails(data[field].id)}}},[_vm._v(" "+_vm._s(data[field].full_name)+" ")])]}}],null,false,2099816328)}),_c('Column',{attrs:{"field":"legal_name","header":"Service name","sortable":""}}),_c('Column',{attrs:{"field":"country","header":"Country","sortable":""}}),_c('Column',{attrs:{"field":"services_type","header":"Type of services","sortable":""}}),_c('Column',{attrs:{"field":"price","header":"Price","sortable":""}}),_c('Column',{attrs:{"field":"benefit_desc","header":"Description of benefit","sortable":""}}),_c('Column',{attrs:{"field":"deadline","header":"Service time deadline","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
var field = ref.field;
return [_c('div',{staticClass:"col-deadline"},[_c('span',[_c('span',{staticClass:"num"},[_vm._v(_vm._s(data[field].days))]),_vm._v(" Days")]),_vm._v(" "),_c('span',{staticClass:"sep"},[_vm._v(" / ")]),_vm._v(" "),_c('span',[_c('span',{staticClass:"num"},[_vm._v(_vm._s(data[field].hours))]),_vm._v(" h")])])]}}],null,false,3068873659)}),_c('Column',{attrs:{"header":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"col-dark"},[_c('div',{staticClass:"table-col-icon",on:{"click":function($event){return _vm.legalServicesApply(data)}}},[_c('Check',{staticStyle:{"width":"30px","height":"30px"}})],1)])]}}],null,false,3199128411)})],1):_vm._e(),(_vm.pills.active == 'complianceServices')?_c('DataTable',{attrs:{"value":_vm.complianceServices.body}},[_c('Column',{attrs:{"field":"serial","header":"#"}}),_c('Column',{attrs:{"field":"user","header":"Name","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
var field = ref.field;
return [_c('div',{staticClass:"user-details-link",on:{"click":function($event){return _vm.gotoUserDetails(data[field].id)}}},[_vm._v(" "+_vm._s(data[field].full_name)+" ")])]}}],null,false,2099816328)}),_c('Column',{attrs:{"field":"service_name","header":"Service name","sortable":""}}),_c('Column',{attrs:{"field":"country","header":"Country","sortable":""}}),_c('Column',{attrs:{"field":"services_type","header":"Type of services","sortable":""}}),_c('Column',{attrs:{"field":"price","header":"Price","sortable":""}}),_c('Column',{attrs:{"field":"benefit_desc","header":"Description of benefit","sortable":""}}),_c('Column',{attrs:{"field":"deadline","header":"Service time deadline","sortable":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
var field = ref.field;
return [_c('div',{staticClass:"col-deadline"},[_c('span',[_c('span',{staticClass:"num"},[_vm._v(_vm._s(data[field].days))]),_vm._v(" Days")]),_vm._v(" "),_c('span',{staticClass:"sep"},[_vm._v(" / ")]),_vm._v(" "),_c('span',[_c('span',{staticClass:"num"},[_vm._v(_vm._s(data[field].hours))]),_vm._v(" h")])])]}}],null,false,3068873659)}),_c('Column',{attrs:{"header":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"col-dark"},[_c('div',{staticClass:"table-col-icon",on:{"click":function($event){return _vm.complianceServicesApply(data)}}},[_c('Check',{staticStyle:{"width":"30px","height":"30px"}})],1)])]}}],null,false,3170073957)})],1):_vm._e(),(_vm.bankOffers.paginationData)?_c('pagination',{attrs:{"data":_vm.bankOffers.paginationData},on:{"pagination-change-page":_vm.bankOffersGet}},[_c('span',{attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_vm._v("<")]),_c('span',{attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v(">")])]):_vm._e(),(_vm.legalServices.paginationData)?_c('pagination',{attrs:{"data":_vm.legalServices.paginationData},on:{"pagination-change-page":_vm.legalServicesGet}},[_c('span',{attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_vm._v("<")]),_c('span',{attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v(">")])]):_vm._e(),(_vm.complianceServices.paginationData)?_c('pagination',{attrs:{"data":_vm.complianceServices.paginationData},on:{"pagination-change-page":_vm.complianceServicesGet}},[_c('span',{attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_vm._v("<")]),_c('span',{attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v(">")])]):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }