<template>
  <overlay-scrollbars class="page page-offer shapes-bg">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">Applied Offers</span>
      </h2>
    </div>

    <div class="box box-bg-shapes">
      <div class="box-header">
        <div class="pills">
          <div v-for="(item, index) in pills.data" :key="index" class="pill"
            :class="{ 'pill_active': pills.active == item.slug }" @click="changePill(item.slug)">{{ item.text }}</div>
        </div>
      </div>
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="bankOffers.body" v-if="pills.active == 'bankOffers'">
            <Column field="serial" header="#"></Column>
            <Column field="user" header="Name" sortable>
              <template #body="{ data, field }">
                <div class="user-details-link" @click="gotoUserDetails(data[field].id)">
                  {{ data[field].full_name }}
                </div>
              </template>
            </Column>
            <Column field="acc_type" header="Type of Account" sortable></Column>
            <Column field="oif" header="Open/Integration fee" sortable></Column>
            <Column field="incoming_fee" header="Incoming fee" sortable></Column>
            <Column field="outgoing_fee" header="Outgoing fee" sortable></Column>
            <Column field="onboarding" header="Onboarding time" sortable></Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="bankOffersApply(data)">
                    <Check style="width: 30px; height: 30px;" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <DataTable :value="legalServices.body" v-if="pills.active == 'legalServices'">
            <Column field="serial" header="#"></Column>
            <Column field="user" header="Name" sortable>
              <template #body="{ data, field }">
                <div class="user-details-link" @click="gotoUserDetails(data[field].id)">
                  {{ data[field].full_name }}
                </div>
              </template>
            </Column>
            <Column field="legal_name" header="Service name" sortable></Column>
            <Column field="country" header="Country" sortable></Column>
            <Column field="services_type" header="Type of services" sortable></Column>
            <Column field="price" header="Price" sortable></Column>
            <Column field="benefit_desc" header="Description of benefit" sortable></Column>
            <Column field="deadline" header="Service time deadline" sortable>
              <template #body="{ data, field }">
                <div class="col-deadline">
                  <span><span class="num">{{ data[field].days }}</span> Days</span> <span class="sep"> / </span> <span><span class="num">{{ data[field].hours }}</span> h</span>
                </div>
              </template>
            </Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="legalServicesApply(data)">
                    <Check style="width: 30px; height: 30px;" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
          <DataTable :value="complianceServices.body" v-if="pills.active == 'complianceServices'">
            <Column field="serial" header="#"></Column>
            <Column field="user" header="Name" sortable>
              <template #body="{ data, field }">
                <div class="user-details-link" @click="gotoUserDetails(data[field].id)">
                  {{ data[field].full_name }}
                </div>
              </template>
            </Column>
            <Column field="service_name" header="Service name" sortable></Column>
            <Column field="country" header="Country" sortable></Column>
            <Column field="services_type" header="Type of services" sortable></Column>
            <Column field="price" header="Price" sortable></Column>
            <Column field="benefit_desc" header="Description of benefit" sortable></Column>
            <Column field="deadline" header="Service time deadline" sortable>
              <template #body="{ data, field }">
                <div class="col-deadline">
                  <span><span class="num">{{ data[field].days }}</span> Days</span> <span class="sep"> / </span> <span><span class="num">{{ data[field].hours }}</span> h</span>
                </div>
              </template>
            </Column>
            <Column header="">
              <template #body="{ data }">
                <div class="col-dark">
                  <div class="table-col-icon" @click="complianceServicesApply(data)">
                    <Check style="width: 30px; height: 30px;" />
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>

          <pagination v-if="bankOffers.paginationData" :data="bankOffers.paginationData"
            @pagination-change-page="bankOffersGet">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
          <pagination v-if="legalServices.paginationData" :data="legalServices.paginationData"
            @pagination-change-page="legalServicesGet">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
          <pagination v-if="complianceServices.paginationData" :data="complianceServices.paginationData"
            @pagination-change-page="complianceServicesGet">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Check from '@/components/img/Check.vue'

export default {
  name: 'AppliedOffers',
  components: {
    Check,
  },
  data() {
    return {
      pills: {
        data: [
          { slug: 'bankOffers', text: 'Bank & Merchant Offers' },
          { slug: 'legalServices', text: 'Legal Services' },
          { slug: 'complianceServices', text: 'Compliance Services' },
        ],
        active: 'bankOffers',
      },
      bankOffers: {
        body: [],
        paginationData: null,
      },
      legalServices: {
        body: [],
        paginationData: null,
      },
      complianceServices: {
        body: [],
        paginationData: null,
      },
    };
  },
  created() {
    this.bankOffersGet()
  },
  methods: {
    gotoUserDetails(id) {
      this.$router.push({ name: "CustomerDetail", params: { id: id } })
    },
    changePill(slug) {
      this.pills.active = slug;
      this[slug + 'Get']();
    },
    bankOffersGet(page = 1) {
      axios.get('admin/all-offers/applied/bank?page=' + page)
        .then(response => {
          if (!response.data) return false

          this.bankOffers.body = []
          this.bankOffers.paginationData = response.data
          this.bankOffers.paginationData.data.forEach(el => {
            const timeLeftDaysNumber = (el.offer.time_left) ? el.offer.time_left.days_number : ''
            const timeLeftHoursNumber = (el.offer.time_left) ? el.offer.time_left.hours_number : ''

            this.bankOffers.body.push({
              id: el.id,
              serial: el.offer.serial_number.toString(),
              user: { full_name: el.user.full_name, id: el.user.id },
              institution: el.offer.financial_institution_name,
              acc_type: el.offer.account_type,
              oif: this.number_format(el.offer.open_integration_fee, 2, '.', ' ') + '€',
              incoming_fee: el.offer.incoming_fee,
              outgoing_fee: el.offer.outgoing_fee,
              onboarding: timeLeftDaysNumber + 'd ' + timeLeftHoursNumber + 'h',
            })
          })
        })
        .catch((errors) => console.dir(errors))
    },
    bankOffersApply(payload) {
      axios.delete('admin/all-offers/cancel/user-offer/' + payload.id)
        .then(response => {
          this.bankOffers.body = []
          this.bankOffers.paginationData.data = this.bankOffers.paginationData.data.filter(user_offer => user_offer.id !== payload.id)
          this.bankOffers.paginationData.data.forEach(el => {
            const timeLeftDaysNumber = (el.offer.time_left) ? el.offer.time_left.days_number : ''
            const timeLeftHoursNumber = (el.offer.time_left) ? el.offer.time_left.hours_number : ''

            this.bankOffers.body.push({
              id: el.id,
              serial: el.offer.serial_number.toString(),
              user: { full_name: el.user.full_name, id: el.user.id },
              institution: el.offer.financial_institution_name,
              acc_type: el.offer.account_type,
              oif: this.number_format(el.offer.open_integration_fee, 2, '.', ' ') + '€',
              incoming_fee: el.offer.incoming_fee,
              outgoing_fee: el.offer.outgoing_fee,
              onboarding: timeLeftDaysNumber + 'd ' + timeLeftHoursNumber + 'h',
            })
          })
        })
        .catch((errors) => console.dir(errors))
    },
    legalServicesGet(page = 1) {
      axios.get('admin/all-offers/applied/legal-services?page=' + page)
        .then(response => {
          if (!response.data) return false

          this.legalServices.body = []
          this.legalServices.paginationData = response.data

          this.legalServices.paginationData.data.forEach(el => {
            this.legalServices.body.push({
              id: el.id,
              serial: el.legal_service.serial_number.toString(),
              user: { full_name: el.user.full_name, id: el.user.id },
              legal_name: el.legal_service.legal_name,
              country: el.legal_service.country ? el.legal_service.country.name : '',
              services_type: el.legal_service.services_type,
              price: this.number_format(el.legal_service.price, 2, '.', ' ') + '€',
              benefit_desc: el.legal_service.benefit_description,
              deadline: {days: el.legal_service.time_left.days_number, hours: el.legal_service.time_left.hours_number} 
              // deadline: { days: el.legal_service.time_left.days_number, hours: el.legal_service.time_left.hours_number }
            })
          })
        })
        .catch((errors) => console.dir(errors))
    },
    legalServicesApply(payload) {
      axios.delete('admin/all-offers/cancel/user-legal-services/' + payload.id)
        .then(response => {
          this.legalServices.body = []
          this.legalServices.paginationData.data = this.legalServices.paginationData.data.filter(user_offer => user_offer.id !== payload.id)

          this.legalServices.paginationData.data.forEach(el => {
            this.legalServices.body.push({
              id: el.id,
              serial: el.legal_service.serial_number.toString(),
              user: { full_name: el.user.full_name, id: el.user.id },
              legal_name: el.legal_service.legal_name,
              country: el.legal_service.country ? el.legal_service.country.name : '',
              services_type: el.legal_service.services_type,
              price: this.number_format(el.legal_service.price, 2, '.', ' ') + '€',
              benefit_desc: el.legal_service.benefit_description,
              deadline: {days: el.legal_service.time_left.days_number, hours: el.legal_service.time_left.hours_number} 
            })
          })
        })
        .catch((errors) => console.dir(errors))
    },
    complianceServicesGet(page = 1) {
      axios.get('admin/all-offers/applied/compliance-services?page=' + page)
        .then(response => {
          if (!response.data) return false

          this.complianceServices.body = []
          this.complianceServices.paginationData = response.data
          this.complianceServices.paginationData.data.forEach(el => {
            this.complianceServices.body.push({
              id: el.id,
              serial: el.compliance_service.serial_number.toString(),
              user: { full_name: el.user.full_name, id: el.user.id },
              service_name: el.compliance_service.name,
              country: el.compliance_service.country ? el.compliance_service.country.name : '',
              services_type: el.compliance_service.services_type,
              price: this.number_format(el.compliance_service.price, 2, '.', ' ') + '€',
              benefit_desc: el.compliance_service.benefit_description,
              deadline: {days: el.compliance_service.time_left.days_number, hours: el.compliance_service.time_left.hours_number} 
            })
          })
        })
        .catch((errors) => console.dir(errors))
    },
    complianceServicesApply(payload) {
      axios.delete('admin/all-offers/cancel/user-compliance-services/' + payload.id)
        .then(response => {
          this.complianceServices.body = []
          this.complianceServices.paginationData.data = this.complianceServices.paginationData.data.filter(user_offer => user_offer.id !== payload.id)
          this.complianceServices.paginationData.data.forEach(el => {
            this.complianceServices.body.push({
              id: el.id,
              serial: el.compliance_service.serial_number.toString(),
              user: { full_name: el.user.full_name, id: el.user.id },
              service_name: el.compliance_service.name,
              country: el.compliance_service.country ? el.compliance_service.country.name : '',
              services_type: el.compliance_service.services_type,
              price: this.number_format(el.compliance_service.price, 2, '.', ' ') + '€',
              benefit_desc: el.compliance_service.benefit_description,
              deadline: {days: el.compliance_service.time_left.days_number, hours: el.compliance_service.time_left.hours_number} 
            })
          })
        })
        .catch((errors) => console.dir(errors))
    },
  },
}
</script>

<style lang="scss" scoped>
.box-container {
  padding-bottom: 1.25rem;
}
</style>