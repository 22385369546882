<template>
    <div class="modal__wrapper">
      <div class="modal__close">
        <div class="btn" @click="close">
          <Close class="img" />
        </div>
      </div>
      <div class="modal__header">
        <div class="ttl">{{ isEdit ? 'Edit' : 'Create' }} Bank Sugestion</div>
      </div>
      <div class="modal__body">
        <div class="row">
          <div class="label">Name</div>
          <div class="data">
            <input type="text" class="inpt" v-model="form.name">
          </div>
        </div>
        <div class="row">
          <div class="label">Bank Location</div>
          <div class="data">
            <input type="text" class="inpt" v-model="form.geo">
          </div>
        </div>
        <div class="row">
          <div class="label">Description</div>
          <div class="data">
            <input type="text" class="inpt" v-model="form.description">
          </div>
        </div>
        <div class="row">
          <div class="label">Rate</div>
          <div class="data">
            <input type="number" min="0" max="5" class="inpt" v-model="form.rate">
          </div>
        </div>
        <div class="row">
          <div class="label">Price</div>
          <div class="data">
            <input type="number" class="inpt" v-model="form.price">
          </div>
        </div>
  
        <span v-if="error && error.length > 0" class="invalid-feedback">{{ error }}</span>
      </div>
      <div class="modal__footer">
        <div class="btn" @click="save">Save</div>
      </div>
    </div>
  </template>
    
  <script>
  import Close from './../img/Close.vue'
  
  export default {
    components: {
      Close
    },
    props: {
      options: {
        type: Object,
        default: null
      }
    },
    data: () => ({
      allCurrencies: null,
      form: {
        name: null,
        geo: null,
        description: null,
        rate: null,
        price: null
      },
      error: null
    }),
    computed: {
      isEdit() {
        return this.options.isEdit
      }
    },
    created() {
      this.updateForm()
    },
    methods: {
      updateForm() {
        if (!this.isEdit) return;
  
        axios.get('admin/sugestions/' + this.options.id)
          .then(res => {
            this.form.name = res.data.name
            this.form.price = res.data.price
            this.form.geo = res.data.geo
            this.form.rate = res.data.rate
            this.form.description = res.data.description
          })
          .catch(err => console.dir(err))
      },
      async save() {
        const sugestion = {
          name: this.form.name,
          geo: this.form.geo,
          description: this.form.description,
          rate: this.form.rate,
          price: this.form.price
        }
  
        if (this.isEdit) {
          await axios.put(this.options.url, sugestion)
            .then(res => {
              if (res.data.success) {
                this.$noty.success(this.options.successfullMessage)
                this.$store.dispatch('editItemFromTable', {
                  id: this.options.id,
                  table: this.options.table,
                  actionType: this.options.actionType
                })
              }
  
              this.close();
            })
            .catch(error => console.dir(error));
        } else {
          await axios.post('admin/sugestions', sugestion)
            .then(res => {
              this.options.fetchData();
              this.close();
            })
            .catch(error => console.dir(error))
        }
      },
      close() {
        this.$emit("close");
      }
    }
  }
  </script>
    
  <style lang="scss" scoped>
  .modal {
    &__wrapper {
      width: 40rem;
    }
  
    &__header {
      padding: 2.5rem 0.75rem 1.5rem;
    }
  
    &__body {
      flex-direction: column;
      align-content: flex-start;
  
      .row {
        width: 100%;
        display: flex;
        align-items: center;
        padding: .25rem 0;
        margin: .25rem 0 0;
  
        .label {
          min-width: 10rem;
          padding-right: .75rem;
          font-weight: bold;
        }
  
        .data {
          flex-grow: 1;
  
          .inpt {
            width: 100%;
            color: #000000;
  
            &__ta {
              height: 7rem;
            }
          }
  
          .fileBox {
            display: flex;
            align-items: center;
  
            .filename {
              padding-right: .25rem;
            }
          }
        }
  
        &__price {
          .data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
  
            .item {
              display: flex;
              align-items: center;
              margin-bottom: .25rem;
  
              .inputBlock {
                display: inline-flex;
                width: 15rem;
                height: 2.75rem;
                padding: .25rem;
                border: 1px solid #1C31F0;
                border-radius: 6px;
  
                input {
                  flex-grow: 1;
                  min-width: 1rem;
                  height: 100%;
                  text-align: center;
                  border: none;
  
                  &:focus {
                    outline: none;
                  }
                }
  
                select {
                  height: 100%;
                  margin-left: .25rem;
                  padding: .25rem .5rem;
                  color: #fff;
                  background-color: #000;
                  border-radius: .25rem;
                }
              }
            }
          }
        }
  
        .fileBox {
          .btn {
            display: inline-flex;
            line-height: 1;
            color: #fff;
            border-radius: .25rem;
  
            &__fileUpload {
              padding: 0.75rem 1rem 10px;
              text-transform: uppercase;
              background-color: var(--c_green);
            }
          }
        }
      }
  
      .btn {
        display: inline-flex;
        line-height: 1;
        color: #fff;
        border-radius: .25rem;
  
        &__remove {
          padding: .25rem .5rem;
          margin-left: .5rem;
          font-size: 1.25rem;
          background-color: var(--c_error);
        }
  
        &__newPrice {
          padding: .25rem .75rem;
          margin-top: .25rem;
          font-size: 2rem;
          background-color: var(--c_green);
        }
      }
    }
  
    &__footer {
      padding: 1rem 1.5rem 1.5rem;
    }
  }
  </style>
    