<template>
  <overlay-scrollbars class="page page-personal" ref="os">
    <div class="box-container">
      <div class="transparent-box box_b-info">
        <div class="content">
        	<div class="block">
        		<div class="hdr"><div class="ttl">Industries</div><div @click="createIndustry" class="btn create-account-btn">Create</div></div>
        		<Table class="industries" @editItem="editIndustry" @deleteItem="deleteIndustry" :key="industryDataKey" :options="industriesData" @change-page="getIndustries" />
        	</div> 
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Table from './../components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'

export default {
	data () {
		return {
			industryDataKey: 0,
			industriesData: {
		      colsWidth: ['80%', '10%'],
		      header: [
		        { ttl: 'Name', info: null },
		        { ttl: '', info: null },
		      ],
		      body: [],
		      paginationData: null,
		    },
		}
	},
	components: {
	    Table,
	    Pagination,
	 }, 
	created () {
		if (this.$store.state.user && !this.$store.state.user.is_super_admin) {
			this.$router.push({ name: "MySettings" })
		}
		this.getIndustries()
	},
	methods: {
		async getIndustries(page = 1) {
			await axios.get('admin/industries?page='+ page)
		        .then(({ data }) => {
		          this.industriesData.paginationData = data
		          this.industriesData.body = []
		          this.industriesData.paginationData.data.forEach(el => {
		            this.industriesData.body.push([
		              el.name, { type: 'edit-delete-actions', id: el.id}
		            ])
		          })
		        })
		        .catch((errors) => {
		          console.dir(errors)
		        })
		},
		createIndustry () {
			this.$store.commit('setModal', {
		        template: 'industry',
		        options: {
		          id: null,
		          actionType: 'create',
		          table: 'industries',
		          objectType: 'industries',
		          url: 'admin/industries',
		          successfullMessage: 'You successfully created industry',
		        }
		      })
		},
		editIndustry (obj) {
			this.$store.commit('setModal', {
		        template: 'industry',
		        options: {
		          id: obj.id,
		          actionType: 'edit',
		          table: 'industries',
		          objectType: 'industries',
		          url: 'admin/industries/' + obj.id,
		          successfullMessage: 'You successfully edited industry',
		        }
		      }) 
		},
		deleteIndustry (obj) {
			this.$store.commit('setModal', {
	        template: 'delete',
	        options: {
	          id: obj.id,
	          actionType: 'delete',
	          table: 'industries',
	          type: 'industry',
	          url: 'admin/industries/' + obj.id,
	          successfullMessage: 'You successfully deleted industry',
	        }
	      })
		},
	    updateCurrentPage(tableName, actionType) {
	    	console.log(tableName, actionType)
	      if (tableName == 'industries') {
	          let currentPage = 1
	          if (actionType == 'edit' || actionType == 'delete') {
	          	try {
			            currentPage = document.getElementsByClassName(tableName)[0]
			            .getElementsByClassName('pagination')[0]
			            .getElementsByClassName('active')[0]
			            .getElementsByClassName('page-link')[0]
			            .innerText.trim().replace( /\D+/g, '')
			        } catch (err) {
		            console.log('Error. Please, try again.');
		          }  
	          }
	          this.getIndustries(currentPage)
	      } 
	    }
	},
	  watch: {
	    '$store.state.deleteItemFromTableData': function(data) {
	        this.updateCurrentPage(data.table, data.actionType)
	    },
	    '$store.state.editItemFromTableData': function(data) {
	        this.updateCurrentPage(data.table, data.actionType)
	    },
	    '$store.state.user': function() { 
	      if (this.$store.state.user && !this.$store.state.user.is_super_admin) {
			this.$router.push({ name: "MySettings" })
		  }
	    }
	  }
}
</script>

<style scoped>
.create-account-btn {
    border-radius: 1rem;
    padding: 10px;
    margin-left: 30px;
    background-color: #1C31F0;
}

.content .block .hdr {
    display: flex;
    align-items: center;
    margin-left: 0.75rem;
    margin-bottom: 0.5rem;
}

.providers-ttl {
	margin-top: 40px;
}
</style>