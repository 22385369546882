<template>
  <overlay-scrollbars class="page page-offer shapes-bg" ref="os">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <span class="bc-first">Offers</span>
      </h2>
    </div>
    <div class="box box-bg-shapes">
      <div class="box-body">
        <div class="main-table offers-table">
          <DataTable :value="requestsTableData.body">
            <Column field="user_name" header="User">
              <template #body="{ data, field }">
                <router-link :to="{name: 'CustomerDetail', params: {id: data['id']}}">{{ data[field] }}</router-link>
              </template>
            </Column>
            <Column field="ba_payment_methods_text" header="Payment methods" sortable></Column>
            <Column field="ba_minimum_ammount" header="Bank minimum ammount" sortable></Column>
            <Column field="ba_maximum_ammount" header="Bank maximum ammount" sortable></Column>
            <Column field="ba_payment_purpose" header="Purpose of payment" sortable></Column>
            <!-- <Column field="incoming_fee" header="Bank incoming fee" sortable></Column> -->
            <!-- <Column field="outgoing_fee" header="Bank outgoing fee" sortable></Column> -->
            <Column field="ba_recall_reason" header="Recall reason" sortable></Column>
            <Column field="ba_account_currencies_text" header="Account currencies" sortable></Column>
            <Column field="ma_card_types_text" header="Type of cards" sortable></Column>
            <Column field="ma_minimum_ammount" header="Merchant minimum ammount" sortable></Column>
            <Column field="ma_maximum_ammount" header="Merchant maximum ammount" sortable></Column>
            <Column field="ma_mc_code_type" header="MC code" sortable></Column>
            <Column field="ma_incoming_fee" header="Merchant incoming fee" sortable></Column>
            <Column field="ma_outgoing_fee" header="Merchant outgoing fee" sortable></Column>
            <Column field="ma_chargeback_reason" header="Chargeback reason" sortable></Column>
            <Column field="ma_processing_currencies_text" header="Processing currencies" sortable></Column>
            <Column field="account_types" header="Account Types" sortable></Column>
            <Column field="company.name" header="Company" sortable></Column>
          </DataTable>
          <pagination v-if="requestsTableData.paginationData" :data="requestsTableData.paginationData"
            @pagination-change-page="getRequests">
            <span slot="prev-nav">&lt;</span>
            <span slot="next-nav">&gt;</span>
          </pagination>
        </div>
      </div>
    </div>

            <!-- <Table :key="requestsTableKey" :options="requestsTableData" @change-page="getRequests"
              @scroll-top="toElTop" /> -->
        
  </overlay-scrollbars>
</template>

<script>

export default {
  components: {
   
  },
  data: () => ({
    requestsTableData: {
      header: [
        { ttl: 'User', info: null },
        { ttl: 'Payment methods', info: null },
        { ttl: 'Bank minimum ammount', info: null },
        { ttl: 'Bank maximum ammount', info: null },
        { ttl: 'Purpose of payment', info: null },
        { ttl: 'Bank incoming fee', info: null },
        { ttl: 'Bank outgoing fee', info: null },
        { ttl: 'Recall reason', info: null },
        { ttl: 'Account currencies', info: null },
        { ttl: 'Type of cards', info: null },
        { ttl: 'Merchant minimum ammount', info: null },
        { ttl: 'Merchant maximum ammount', info: null },
        { ttl: 'MC code', info: null },
        { ttl: 'Merchant incoming fee', info: null },
        { ttl: 'Merchant outgoing fee', info: null },
        { ttl: 'Chargeback reason', info: null },
        { ttl: 'Processing currencies', info: null },
        { ttl: '', info: null },
      ],
      body: [],
      paginationData: null,
    },
  }),
  created() {
    this.getRequests()
  },
  methods: {
    getRequests(page = 1) {
      axios.get('admin/request?page=' + page)
        .then(response => {
          this.requestsTableData.paginationData = response.data.payment_settings
          this.requestsTableData.body = [];

          response.data.payment_settings.data.forEach(el => {
            const requestObj = {
              id: el.user.id,
              user_name: el.user.full_name,
              ba_payment_methods_text: el.ba_payment_methods_text,
              ba_minimum_ammount: el.ba_minimum_ammount?.toString(),
              ba_maximum_ammount: el.ba_maximum_ammount?.toString(),
              ba_payment_purpose: el.ba_payment_purpose,
              ma_incoming_fee: el.ma_incoming_fee?.toString(),
              ba_recall_reason: el.ba_recall_reason,
              ba_account_currencies_text: el.ba_account_currencies_text,
              ma_card_types_text: el.ma_card_types_text,
              ma_minimum_ammount: el.ma_minimum_ammount?.toString(),
              ma_maximum_ammount: el.ma_maximum_ammount?.toString(),
              ma_mc_code_type: el.ma_mc_code_type,
              ma_incoming_fee: el.ma_incoming_fee?.toString(),
              ma_outgoing_fee: el.ma_outgoing_fee?.toString(),
              ma_chargeback_reason: el.ma_chargeback_reason,
              ma_processing_currencies_text: el.ma_processing_currencies_text,
              account_types: el.account_types,
              company: el.company
            }

            this.requestsTableData.body.push(requestObj)
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>