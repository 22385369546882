<template>
  <div class="notifications" :class="{ 'open': dropdown }" @click="dropdown = !dropdown" v-click-outside="dropdownClose">
    <div class="btn btn-notif btn_mark">
      <div v-if="newNotificationsCount && newNotificationsCount !== 0" class="number">{{ newNotificationsCount }}</div>
      <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.6669 9.18181C19.6669 7.44584 18.9965 5.78098 17.803 4.55347C16.6096 3.32596 14.991 2.63635 13.3033 2.63635C11.6155 2.63635 9.99691 3.32596 8.8035 4.55347C7.61008 5.78098 6.93963 7.44584 6.93963 9.18181C6.93963 16.8182 3.75781 19 3.75781 19H22.8487C22.8487 19 19.6669 16.8182 19.6669 9.18181Z"
          stroke="#EEF8FF" stroke-width="1.63636" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M15.1404 23.3636C14.9539 23.6943 14.6863 23.9687 14.3643 24.1595C14.0423 24.3503 13.6772 24.4507 13.3056 24.4507C12.9339 24.4507 12.5688 24.3503 12.2468 24.1595C11.9248 23.9687 11.6572 23.6943 11.4707 23.3636"
          stroke="#EEF8FF" stroke-width="1.63636" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
    <div class="list">
      <div v-if="notifications.length > 0">
        <div v-for="(notification, index) in notifications" :key="index">
          <div @click="viewUpdateProfileFields(notification)"
            v-if="notification.notification.data.type === 'update-profile'" class="notification-msg">{{
              textNotification(notification) }}</div>
          <div @click="viewUpdatedBusinessInformationFields(notification)"
            v-if="notification.notification.data.type === 'update-business-information'" class="notification-msg">{{
              textNotification(notification) }}</div>
          <div @click="viewUpdatedLogo(notification)" v-if="notification.notification.data.type === 'update-logo'"
            class="notification-msg">{{ textNotification(notification) }}</div>
          <div @click="viewUpdateRequest(notification)" v-if="notification.notification.data.type === 'update-request'"
            class="notification-msg">{{ textNotification(notification) }}</div>
          <div @click="viewApplyOffer(notification)" v-if="notification.notification.data.type === 'apply-offer'"
            class="notification-msg">{{ textNotification(notification) }}</div>
          <div @click="viewCancelOffer(notification)" v-if="notification.notification.data.type === 'cancel-offer'"
            class="notification-msg">{{ textNotification(notification) }}</div>
          <div @click="viewCompliance(notification)" v-if="notification.notification.data.type === 'update-compliance'"
            class="notification-msg">{{ textNotification(notification) }}</div>
          <div v-if="notification.notification.data.type === 'apply-legal-atlas'" class="notification-msg">{{
            textNotification(notification) }}</div>
          <div v-if="notification.notification.data.type === 'cancel-legal-atlas'" class="notification-msg">{{
            textNotification(notification) }}</div>
          <div v-if="notification.notification.data.type === 'chat-select-offer'" class="notification-msg">{{
            textNotification(notification) }}</div>
          <div v-if="notification.notification.data.type === 'chat-change-offer-status'" class="notification-msg">{{
            textNotification(notification) }}</div>
          <div v-if="notification.notification.data.type === 'create-service-chat'" class="notification-msg"
            @click="goToChat(notification.notification.data.chat_id)">
            {{ textNotification(notification) }}
          </div>
          <div v-if="notification.notification.data.type === 'create-offer-chat'" class="notification-msg"
            @click="goToChat(notification.notification.data.chat_id)">
            {{ textNotification(notification) }}
          </div>
          <div v-if="notification.notification.data.type === 'create-company'
            || notification.notification.data.type === 'create-incorporation-company'
            " class="notification-msg" @click="viewCompany(notification)">
            {{ textNotification(notification) }}
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="loading" class="load">
          <CircleLoad />
        </div>
      </div>
      <div @click="gotoAllNotifications" v-if="!loading" class="all-notifications-btn">All notifications</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CircleLoad from '@/components/img/CircleLoad'

export default {
  name: 'notifications',
  components: {
    CircleLoad
  },
  computed: {
    ...mapGetters({
      newNotificationsCount: 'notifications/unreadCount'
    })
  },
  data() {
    return {
      menu: false,
      isLoaded: false,
      viewList: [],
      viewedList: [],
      viewTimeout: null,
      loading: false,
      notifications: [],
      dropdown: false
    }
  },
  methods: {
    gotoAllNotifications() {
      this.$store.dispatch('notifications/gotoAllNotifications')
    },
    getUnreadNotifications() {
      if (this.loading) {
        return false
      }
      this.loading = true
      return axios.get('admin/notifications-unread', { params: { limit: 10 } })
        .then(response => {
          this.notifications = response.data
        })
        .catch(error => {
          console.error('error get unread notifications', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    dropdownClose() {
      this.dropdown = false
    },
    textNotification(notification) {
      let authorName = notification.author.full_name ? notification.author.full_name : notification.author.member_login
      if (notification.notification.data.type === 'update-profile') {
        const updatedFields = notification.notification.data.updated_field_labels.join(', ')
        return authorName + ' updated ' + updatedFields + ' in his profile'
      }

      if (notification.notification.data.type === 'update-business-information') {
        const updatedFields = notification.notification.data.updated_field_labels.join(', ')
        return authorName + ' updated ' + updatedFields + ' in his business information'
      }

      if (notification.notification.data.type === 'update-logo') {
        return authorName + ' updated logo'
      }

      if (notification.notification.data.type === 'update-request') {
        const updatedFields = notification.notification.data.updated_field_labels.join(', ')
        return authorName + ' updated ' + updatedFields
      }

      if (notification.notification.data.type === 'apply-offer') {
        return authorName + ' applied offer'
      }

      if (notification.notification.data.type === 'cancel-offer') {
        return authorName + ' canceled offer'
      }

      if (notification.notification.data.type === 'update-compliance') {
        const updatedFields = notification.notification.data.updated_fields.join(', ')
        return authorName + ' updated ' + updatedFields
      }

      if (notification.notification.data.type === 'apply-legal-atlas') {
        return authorName + ' applied legal service'
      }

      if (notification.notification.data.type === 'cancel-legal-atlas') {
        return authorName + ' canceled legal service'
      }

      if (notification.notification.data.type === 'chat-select-offer') {
        return authorName + ' selected ' + notification.notification.data.offer_type + ' in chat with ' + notification.notification.data.participant_names
      }

      if (notification.notification.data.type === 'chat-change-offer-status') {
        return authorName + ' changed offer status in chat with ' + notification.notification.data.participant_names
      }

      if (notification.notification.data.type === 'create-service-chat') {
        return authorName + ' applied service and created chat'
      }

      if (notification.notification.data.type === 'create-offer-chat') {
        return authorName + ' applied offer and created chat'
      }

      if (notification.notification.data.type === 'create-company') {
        return authorName + ' created company ' + notification.notification.data.company_name
      }

      if (notification.notification.data.type === 'create-incorporation-company') {
        return authorName + ' created incorporation company ' + notification.notification.data.company_name
      }

      return ''
    },
    viewUpdateProfileFields(notification) {
      this.$store.dispatch('notifications/viewUpdateProfileFields', notification)
    },
    viewUpdatedBusinessInformationFields(notification) {
      this.$store.dispatch('notifications/viewUpdatedBusinessInformationFields', notification)
    },
    viewUpdatedLogo(notification) {
      this.$store.dispatch('notifications/viewUpdatedLogo', notification)
    },
    viewUpdateRequest(notification) {
      this.$store.dispatch('notifications/updateRequest', notification)
    },
    viewApplyOffer(notification) {
      this.$store.dispatch('notifications/applyOffer', notification)
    },
    viewCancelOffer(notification) {
      this.$store.dispatch('notifications/cancelOffer', notification)
    },
    viewCompliance(notification) {
      this.$store.dispatch('notifications/updateCompliance', notification)
    },
    showChats(notification) {
      this.$router.push({ name: "Chat" })
    },
    showAllChats() {
      this.$router.push({ name: "AllChats" })
    },
    goToChat(chatId) {
      this.$router.push({ name: "Chat", params: { chatId: chatId } })
    },
    viewCompany(notification) {
      this.$router.push({
        name: "CustomerDetail", params: {
          id: notification.notification.data.user_id,
          company_id: notification.notification.data.company_id
        }
      })
    },
  },
  watch: {
    dropdown(isOpenedMenu) {
      if (isOpenedMenu === true) {
        this.getUnreadNotifications()
      } else {
        this.notifications = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#header {
  --left_offset: 2rem;

  position: relative;
  left: -2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  width: calc(100% + var(--left_offset));
  max-width: calc(100% + var(--left_offset));
  min-height: var(--header_height);
  padding: .5rem 2.5rem;
  padding-left: calc(2.5rem + var(--left_offset));
  background-color: #24292E;

  @media (max-width: 1366px) {
    padding: .5rem 1rem;
    padding-left: calc(1rem + var(--left_offset));
  }

  .btn,
  a.btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 15px;
    background: radial-gradient(98.96% 98.96% at 32.03% 9.37%, rgba(0, 0, 0, 0) 44.47%, #1C31F0 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
linear-gradient(234.94deg, #1C1C24 1.74%, rgba(252, 252, 252, 0) 105.87%);

    .img {
      width: 1.5rem;
      height: 1.5rem;
    }

    &_mark {
      ::v-deep svg.img path {
        fill: #1C31F0;
      }
    }

    &_mark {
      .number {
        content: '';
        position: absolute;
        top: -4px;
        right: -4px;
        width: 1rem;
        height: 1rem;
        background-color: #1C31F0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 23px;
        height: 23px;
        font-size: 18px;
      }
    }

    &-settings {
      background-color: #1C31F0;
      margin-right: 1.5rem;

      @media (max-width: 1024px) {
        margin-right: .5rem;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .img {
        width: 1rem;
        height: 1rem;
        margin: .5rem;
      }

      .desc {
        font-size: 1.75rem;
        font-weight: 600;
        margin: .5rem 1rem;
        padding-left: .5rem;
        white-space: nowrap;

        @media (max-width: 1366px) {
          margin: .5rem 0;
          font-size: 1.5rem;
        }

        @media (max-width: 1024px) {
          font-size: 1.25rem;
        }
      }
    }
  }

  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .search {
      flex-grow: 1;
      position: relative;
      height: 54px;
      // width: 26rem;
      // width: 25vw;
      max-width: 26rem;
      margin: auto 1rem;

      .img {
        position: absolute;
        top: 50%;
        right: .75rem;
        transform: translateY(-50%);
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .notifications {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: .75rem;
      cursor: pointer;

      @media (max-width: 1024px) {
        margin-left: .5rem;
      }

      .list {
        min-width: 300px !important;
        min-height: 70px !important;
        display: none;
        position: absolute;
        top: 100%;
        right: -1px;
        min-width: 100%;
        text-align: right;
        border-top: none;
        border-bottom-left-radius: .75rem;
        border-bottom-right-radius: .75rem;
        background-color: #1c2126;
        z-index: 2;
        border-radius: 0.75rem;

        .item {
          padding: 1rem;
          text-decoration: none;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;

          &:last-child {
            border-bottom-left-radius: .75rem;
            border-bottom-right-radius: .75rem;
          }

          &:hover {
            background-color: #2f363d;
          }
        }
      }

      &.open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // background-color: #1c2126;

        .ava {
          border-bottom-left-radius: 0;
        }

        .arrow {
          transform: rotate(-90deg);
        }

        .list {
          display: block;
        }
      }
    }
  }
}

.load {
  display: inline-flex;
  min-width: 10rem;
  min-height: 3.5rem;
  padding: .5rem 2.5rem;
  margin: 1.75rem auto 3rem;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 600;
  border: none;
  border-radius: .75rem;
}

.load {
  width: 10rem;
  height: 3rem;

  svg {
    width: 100%;
    height: 100%;
    animation: spin 1s linear infinite;

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.notification-msg {
  width: 20rem;
  padding: .25rem .75rem;
  color: white;
  font-size: 12px;
  background: linear-gradient(-5deg, #13B497 -100%, #05737A 100%);
  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
  border-radius: 14px;
  margin: 1.5rem;
}

.all-notifications-btn {
  border-radius: 1rem;
  padding: 10px;
  margin-left: 30px;
  background-color: #1C31F0;
  text-decoration: unset;
  color: inherit;
  cursor: pointer;
  margin: 2rem 1rem;
}</style>
