var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('overlay-scrollbars',{ref:"os",staticClass:"page page-offer shapes-bg"},[_c('div',{staticClass:"section-title-wrap"},[_c('h2',{staticClass:"page-details__title section-title"},[_c('span',{staticClass:"bc-first"},[_vm._v("Legal net")])])]),_c('div',{staticClass:"box box-bg-shapes"},[_c('div',{staticClass:"box-header"},[_c('div',{staticClass:"box-title"},[_vm._v("Legal Net")]),_c('div',{staticClass:"box-btns"},[_c('div',{staticClass:"btn-gradient",on:{"click":_vm.createLegalService}},[_vm._v("Create")]),_c('div',{staticClass:"btn-provider",on:{"click":function($event){_vm.showProvidersServices = !_vm.showProvidersServices}}},[_c('span',{staticClass:"btn-provider__arrow"},[_vm._v("◀")]),(_vm.showProvidersServices)?_c('span',[_vm._v("Provider")]):_c('span',[_vm._v("User")]),_c('span',{staticClass:"btn-provider__arrow"},[_vm._v("▶")])])])]),_c('div',{staticClass:"box-body"},[_c('div',{staticClass:"main-table offers-table"},[_c('DataTable',{attrs:{"value":_vm.legalAtlasTableData.body}},[_c('Column',{attrs:{"field":"legal_name","header":"Name of Legal Co."}}),_c('Column',{attrs:{"field":"country","header":"Country"}}),_c('Column',{attrs:{"field":"services_type","header":"Services Type"}}),_c('Column',{attrs:{"field":"price","header":"Price"}}),_c('Column',{attrs:{"field":"description","header":"Description of Benefit"}}),_c('Column',{attrs:{"field":"service_time","header":"Service Time"}}),(_vm.showProvidersServices)?_c('Column',{attrs:{"field":"provider","header":"Provider"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
var field = ref.field;
return [_c('div',[_vm._v(" "+_vm._s(data[field].member_login)+" ")])]}}],null,false,4271409230)}):_c('Column',{attrs:{"field":"","header":""}}),_c('Column',{attrs:{"header":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"col-dark"},[_c('div',{staticClass:"table-col-icon",on:{"click":function($event){return _vm.editLegalAtlas(data)}}},[_c('EditIcon',{attrs:{"fill":"#fff"}})],1),_c('div',{staticClass:"table-col-icon",on:{"click":function($event){return _vm.deleteLegalAtlas(data)}}},[_c('TableRemoveItemIcon',{staticStyle:{"width":"30px","height":"30px"}})],1)])]}}])})],1),(_vm.legalAtlasTableData.paginationData)?_c('pagination',{attrs:{"data":_vm.legalAtlasTableData.paginationData},on:{"pagination-change-page":_vm.getLegalServices}},[_c('span',{attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_vm._v("<")]),_c('span',{attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v(">")])]):_vm._e()],1)])]),_c('div',{staticClass:"box box-bg-shapes"},[_c('div',{staticClass:"box-header"},[_c('div',{staticClass:"box-title"},[_vm._v("Packages")]),_c('div',{staticClass:"box-btns"},[_c('div',{staticClass:"btn-gradient",on:{"click":_vm.createPackage}},[_vm._v("Create")])])]),_c('div',{staticClass:"box-body"},[_c('div',{staticClass:"main-table offers-table"},[_c('DataTable',{attrs:{"value":_vm.packagesTable.body}},[_c('Column',{attrs:{"field":"package_type","header":"Package Type"}}),_c('Column',{attrs:{"field":"name","header":"Package Name"}}),_c('Column',{attrs:{"field":"price","header":"Price"}}),_c('Column',{attrs:{"header":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"col-dark"},[_c('div',{staticClass:"table-col-icon",on:{"click":function($event){return _vm.editPackage(data)}}},[_c('EditIcon',{attrs:{"fill":"#fff"}})],1),_c('div',{staticClass:"table-col-icon",on:{"click":function($event){return _vm.deletePackage(data)}}},[_c('TableRemoveItemIcon',{staticStyle:{"width":"30px","height":"30px"}})],1)])]}}])})],1),(_vm.packagesTable.paginationData)?_c('pagination',{attrs:{"data":_vm.packagesTable.paginationData},on:{"pagination-change-page":_vm.getPackages}},[_c('span',{attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_vm._v("<")]),_c('span',{attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v(">")])]):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }