<template>
  <overlay-scrollbars class="page page-personal shapes-bg" ref="os">
    <div class="page-header">
      <div class="section-title-wrap">
        <h2 class="page-details__title section-title">
          <span class="bc-first">Legal Providers</span>
        </h2>
      </div>

      <div class="page-header__btns">
        <div @click="createProvider" class="btn-gradient create-account-btn">Create</div>
        <div v-on:click.stop.prevent="downloadExcel" class="btn-excel">Download to Excel</div>
      </div>
    </div>

    <div class="box box_info box-bg-shapes">
      <div class="box-body">
        <div class="box-card">
          <div class="box-card__img-wrapper">
            <User class="box-card__img" />
          </div>
          <div class="box-card__title">Total legal <br> provider</div>
          <div class="box-card__input">
            <div class="box-card__input-value">{{ legalProvidersInfo.total }}</div>
          </div>
        </div>
        <div class="box-card">
          <div class="box-card__img-wrapper">
            <User class="box-card__img" />
          </div>
          <div class="box-card__title">Fintech legal <br> provider</div>
          <div class="box-card__input">
            <div class="box-card__input-value">{{ legalProvidersInfo.fintechUsersCount }}</div>
          </div>
        </div>
        <div class="box-card">
          <div class="box-card__img-wrapper">
            <User class="box-card__img" />
          </div>
          <div class="box-card__title">Standart legal <br> provider</div>
          <div class="box-card__input">
            <div class="box-card__input-value">{{ legalProvidersInfo.standartUsersCount }}</div>
          </div>
        </div>
        <div class="box-card">
          <div class="box-card__img-wrapper">
            <User class="box-card__img" />
          </div>
          <div class="box-card__title">New legal <br> provider</div>
          <div class="box-card__input">
            <div class="box-card__input-value">{{ legalProvidersInfo.newUsersCount }}</div>
          </div>
        </div>
        <div class="box-card">
          <div class="box-card__img-wrapper">
            <User class="box-card__img" />
          </div>
          <div class="box-card__title">KYB RATE <br> from 60%</div>
          <div class="box-card__input">
            <div class="box-card__input-value">{{ legalProvidersInfo.kybRateCount }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-clients__table-wrap main-table">
      <DataTable :value="legalProviders" dataKey="id" :loading="loading">
        <Column field="serial_number" header="#"></Column>
        <Column field="date_join" header="Date join" sortable></Column>
        <Column field="name" header="Name" sortable></Column>
        <Column field="rating" header="Rating" sortable></Column>
        <Column field="kyb_rate" header="KYB Rate" sortable></Column>
        <Column field="customer_type" header="Customer Type" sortable></Column>
        <Column field="location" header="Location" sortable></Column>
        <Column field="request_change.html" header="Request Change" sortable></Column>
        <Column field="balance" header="Balance" sortable></Column>
        <Column field="discount_code" header="Discount Code" sortable></Column>
        <Column field="is_admin" header="Account Type" sortable></Column>
        <Column>
          <template #body="{ data }">
            <Button class="table-btn" @click="editItem(data)">
              <EditIcon fill="#000" />
            </Button>
            <Button class="table-btn" @click="deleteItem(data)" style="margin-left: 20px">
              <TableRemoveItemIcon />
            </Button>
          </template>
        </Column>
      </DataTable>
      <pagination v-if="legalProvidersPagination" :data="legalProvidersPagination"
        @pagination-change-page="getLegalProviders">
        <span slot="prev-nav">&lt;</span>
        <span slot="next-nav">&gt;</span>
      </pagination>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Pen from './../components/img/Pen.vue'
import User from './../components/img/User.vue'
import Table from './../components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'
import TableRemoveItemIcon from '@/components/icons/TableRemoveItemIcon.vue';
import EditIcon from "@/components/icons/EditIcon.vue";
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    Pen,
    User,
    Pagination,
    Table,
    TableRemoveItemIcon,
    EditIcon,
  },
  data: () => ({
    legalProvidersTableKey: 0,
    loading: false
  }),
  created() {
    this.getLegalProviders()
    this.getLegalProvidersInfo()
  },
  methods: {
    ...mapActions({
      getLegalProviders: 'legal_providers/getLegalProviders',
      getLegalProvidersInfo: 'legal_providers/getLegalProvidersInfo'
    }),
    toElTop(el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
    deleteItem(obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          table: 'legal-providers',
          type: 'legal provider',
          url: 'admin/legal-provider/' + obj.id,
          successfullMessage: 'You successfully deleted legal provider',
        }
      })
    },
    editItem(obj) {
      this.$router.push({ name: "CustomerDetail", params: { id: obj.id, isProvider: true } })
    },
    downloadExcel() {
      axios.post('admin/customers/create-excel-report')
        .then(function (response) {
          const url = response.data
          const link = document.createElement('a')
          link.href = url
          document.body.appendChild(link)
          link.click()
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    createProvider() {
      this.$store.commit('setModal', {
        template: 'create-legal-provider',
        options: {}
      })
    }
  },
  watch: {
    '$store.state.deleteItemFromTableData': function (data) {
      this.getLegalProviders();
      this.getLegalProvidersInfo();
    }
  },
  computed: {
    ...mapState({
      legalProviders: state => state.legal_providers.legalProviders,
      legalProvidersPagination: state => state.legal_providers.legalProvidersPagination,
      legalProvidersInfo: state => state.legal_providers.legalProvidersInfo,
    }),
  },
}
</script>

<style lang="scss" scoped>
.box_info {
  .box-body {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(5, 1fr);
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;

  &__btns {
    display: flex;
  }

  .section-title-wrap {
    margin: 0;
  }
}
</style>
