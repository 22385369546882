<template>
    <div class="modal__wrapper">
        <div class="modal__close">
            <div class="btn" @click="close">
                <Close class="img" />
            </div>
        </div>
        <div class="modal__header">
            <div class="ttl">
                Company {{ options.company.name }}
            </div>
        </div>
        <div class="modal__body">
            <overlay-scrollbars>
                <h2>Company files</h2>
                <div class="files">
                    <div class="file" v-for="file in files">
                        <a :href="backendPath + file.path">{{ file.original_name }}</a>
                        <div class="file__delete" @click="deleteFile(file.id)">
                            <TableRemoveItemIcon />
                        </div>
                    </div>
                </div>
            </overlay-scrollbars>
        </div>
        <div class="modal__footer">
            <!-- <div class="options">
                <div class="btn btn__save">Save data</div>
            </div> -->
        </div>
    </div>
</template>
  
<script>
import Close from './../img/Close.vue'
import TableRemoveItemIcon from '@/components/icons/TableRemoveItemIcon.vue';

export default {
    components: {
        Close,
        TableRemoveItemIcon
    },
    props: {
        options: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            files: []
        };
    },
    created() {
        this.init()
    },
    computed: {
        backendPath() {
            return process.env.VUE_APP_BACKEND_URL + '/storage/'
        }
    },
    methods: {
        init() {
            if (!this.options.company) return

            this.files = this.options.company.files;
        },
        deleteFile(id) {
            const data = {
                file_id: id
            }

            axios.delete('admin/user-company/delete-file', {data: data})
                .then(res => {
                    if(res.data.status == 'success'){
                        this.files = this.files.filter((file) => file.id !== id)
                    }
                })
                .catch(error => console.dir(error))
        },
        close() {
            this.$emit("close");
        }
    },
};
</script>
  
<style lang="scss" scoped>
.files {
    margin-top: 30px;

    .file {
        margin-bottom: 10px;
        display: flex;

        &__delete {
            margin-left: 10px;
            cursor: pointer;
        }
    }
}

.modal {
    &__body {
        height: 68vh;
        padding-right: 2.5rem;
        overflow: hidden;

        .os-host {
            height: 100%;
            width: 100%;
        }
    }

    .form {
        width: 100%;
        padding-right: 1rem;

        &-group {
            --select_height: 46px;

            label {
                font-weight: 700;
                font-size: 20px;
                line-height: 2;
            }

            input,
            select {
                min-width: 100%;
                max-width: 100%;
                height: var(--select_height);
                padding: 12px 25px;
                background-color: #fff;
                border: 1px solid #E5E5E5;
                border-radius: 24px;
                outline: none;
            }

            select {
                appearance: none;
                cursor: pointer;
            }
        }
    }

    .select {
        &-wrapper {
            position: relative;
            width: 100%;
        }

        &-arrow {
            position: absolute;
            top: calc(var(--select_height) / 2);
            right: 1.5rem;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            pointer-events: none;
        }
    }

    &__footer {
        .options {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .btn {
        &__delete {
            background: var(--c_error);
        }
    }
}
</style>
  