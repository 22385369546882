<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close"/>
    </div>
    <div class="modal__header">
      <div class="sbttl">
        Edit chat settings
      </div>
    </div>
    <div class="modal__body">
      <div>
        <div class="row">
            <div class="row">
              <input type="checkbox" v-model="options.applyOffer">
              <label class="apply-offer-label">Apply offer</label>
            </div>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save</div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  components: {},
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapMutations({
      changeChatRoomApplyOffer: 'CHANGE_CHAT_ROOM_APPLY_OFFER',
    }),
    async submit() {
      const that = this
      axios.post('chat/common-settings/' + this.options.activeRoom.id, {
          apply_offer: this.options.applyOffer,
        })
      .then(function (response) { 
        if (response.data.success) {
          response.data.updated_fields.forEach(field => {
            if (field == 'apply_offer') {
              that.changeChatRoomApplyOffer({
                apply_offer: that.options.applyOffer,
                room_id: that.options.activeRoom.id,
                time: +Date.now()
              })
            }
          })
          that.$noty.success('You successfully saved chat settings')
        }
        that.close()
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>

.modal__wrapper {
  max-height: 40rem;
  width: 30rem;
}

.modal__close {
  .btn::before {
    content: '+';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    color: white;
    font-size: 2.25rem;
    font-weight: 300;
  }
}

.invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.form-control {
    width: 100%;
    height: 100%;
    color: #ffffff;
    background-color: #1C2126;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin: auto 0rem;
    border: 1px solid #2F363D;
    border-radius: 0.75rem;
    box-shadow: 0px 11px 23px rgb(0 0 0 / 2%);
    outline: none;
    -webkit-appearance: none;
}

.modal__footer {
  .btn {
    font-size: 18px;
    padding: 1rem 3rem;
    margin: 0.25rem 1rem;
    background: var(--gr_new);
    box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
    border-radius: 6px;
  }
}

.participant-item {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  position: relative;
}

.avatar-img {
  display: inline;
  margin-right: 20px;
}

.avatar-img img {
  width: 30px;
  display: inline;
  border-radius: 10px;
}

.participant-checkbox {
  border-radius: 5px;
  position: absolute;
  right: 0;
}

.participants-block {
  width: 25rem;
}

.form-control {
  background-color: #ffffff;
  color: var(--c_black);
}

.apply-offer-label {
  margin-left: 10px;
}
</style>
