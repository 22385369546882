<template>
  <overlay-scrollbars class="page page-customer-detail shapes-bg">
    <div class="section-title-wrap">
      <h2 class="page-details__title section-title">
        <router-link :to="{ name: 'Customer' }" class="bc-first">Customer / </router-link><span>Customer Detail</span>
      </h2>
    </div>

    <div class="content">
      <div class="row customer-detail-info">
        <div v-if="!$route.params.isProvider" @click="showRequestModal" class="show-modal-btn btn-gradient clickable">
          <div class="desc">Customer Request
            <span v-if="customerRequestIsNew" class="badge  new-request-badge">New</span>
          </div>
        </div>
        <div @click="showUploadedFilesModal" class="show-modal-btn btn-gradient clickable">
          <div class="desc">Uploaded Files</div>
        </div>
        <div v-if="!$route.params.isProvider" @click="showDownloadInvoiceModal" class="show-modal-btn btn-gradient clickable">
          <div class="desc">Download Invoice</div>
        </div>
        <div v-if="!$route.params.isProvider" @click="showAppliedOfferModal" class="show-modal-btn btn-gradient clickable">
          <div class="desc">Applied Offer</div>
        </div>
        <div v-if="!$route.params.isProvider" @click="showCreateIndivualOfferModal" class="show-modal-btn btn-gradient clickable">
          <div class="desc">Send individual offer</div>
        </div>
      </div>
    </div>

    <div class="box box_balance box-bg-shapes">
      <div class="box-header">
        <div class="box-title">Balance:</div>
      </div>
      <div class="box-body">
        <div class="box-card">
          <div class="box-card__bg">
            <img src="/img/rating-bg.png" alt="">
          </div>
          <span class="edit-setting" v-if="!showStatusInput" @click="showStatusInput = !showStatusInput">
            <EditSetting class="edit-setting__img" />
          </span>
          <div class="box-card__title">Status Unpaid</div>
          <div class="box-card__input">
            <div class="box-card__input-value" v-if="!showStatusInput">{{ (customer.is_paid) ? 'Paid' : 'Unpaid' }}</div>
            <select class="box-select" v-else v-model="status">
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
            <div @click="saveStatus" class="btn-gradient btn-save-status" v-if="showStatusInput">
              Save
            </div>
          </div>
        </div>
        <div class="box-card">
          <div class="box-card__bg">
            <img src="/img/lupa-bg.png" alt="">
          </div>
          <div class="box-card__title">Pre-check type <br> of risk</div>
          <div class="box-card__input">
            <span class="edit-setting" v-if="!showRiskInput" @click="showRiskInput = !showRiskInput">
              <EditSetting class="edit-setting__img" />
            </span>
            <div class="box-card__input-value" v-if="!showRiskInput">
              {{ customer.risk }}
              <!-- <span v-if="+customer.show_calculated_risk == 0">{{ customer.risk }}</span>
              <span v-else>{{ customer.calculatedRiskName }}</span> -->
            </div>
            <select class="box-select risk-form-control" v-if="showRiskInput" v-model="riskId">
              <option value="null">Select risk</option>
              <option v-for="risk in risks" :key="risk.id" :value="risk.id">{{ risk.name }}</option>
            </select>
            <div @click="saveRisk" class="btn-gradient btn-save-status" v-if="showRiskInput">
              Save
            </div>
            <!-- <div class="tmblr" v-if="showRiskInput">
              <div class="btn btn-tmblr" :class="+customer.show_calculated_risk == 0 ? 'btn-tmblr-r' : 'btn-tmblr-l'"
                @click="toogleShowCalculatedRisk"></div>
              <div class="txt">Manual</div>
            </div> -->
          </div>
        </div>
        <!-- <div class="box-card">
          <div class="box-card__bg">
            <img src="/img/star-bg.png" alt="">
          </div>
          <span class="edit-setting" v-if="!showRatingInput" @click="showRatingInput = !showRatingInput">
            <EditSetting class="edit-setting__img" />
          </span>
          <div class="box-card__title">Rating</div>
          <div class="box-card__input">
            <div class="box-card__input-value" v-if="!showRatingInput">
              {{ customer.rating }}
            </div>
            <div v-else class="row" style="flex-direction: column">
              <input class="box-input rating-input" @input="onRatingChange" type="number" v-model="customer.rating"
                placeholder="Rating">
            </div>
            <div @click="saveRating" class="btn-gradient btn-save-status" v-if="showRatingInput">
              Save
            </div>
          </div>
        </div>

        <div class="box-card">
          <div class="box-card__bg">
            <img src="/img/group-bg.png" alt="">
          </div>
          <span class="edit-setting" v-if="!showKybRateManual" @click="showKybRateManual = !showKybRateManual">
            <EditSetting class="edit-setting__img" />
          </span>
          <div class="box-card__title">
            KYB Rate: {{ isKybRateManual ? customer.kyb_rate_manual : customer.kyb_rate }}
            <br>
            Manual:
          </div>
          <div class="box-card__input">
            <div class="box-card__input-value" v-if="!showKybRateManual">
              {{ isKybRateManual ? 'Yes' : 'No' }}
            </div>
            <div v-else class="row" style="flex-direction: column">
              <input v-if="isKybRateManual" class="box-input" type="text" v-model="customer.kyb_rate_manual"
                placeholder="Rating">
              <div class="tmblr" :class="{ 'tmblr-hide': !isKybRateManual }">
                <div class="btn btn-tmblr" :class="!isKybRateManual ? 'btn-tmblr-l' : 'btn-tmblr-r'"
                  @click="toogleKybRateManual"></div>
                <div class="txt">Manual</div>
              </div>
              <textarea v-model="customer.kyb_rate_comment" rows="5" type="text" placeholder="Comment"
                class="form-control kyb-rate-comment" />
            </div>
            <div @click="saveKybRateManual" class="btn-gradient btn-save-status" v-if="showKybRateManual">
              Save
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="box box_settings">
      <div class="box-body">
        <div class="box-card" v-if="$store.state.user && $store.state.user.is_super_admin">
          <div class="box-card__title">Is Admin: <span v-if="!showChangeAdminRole">{{ (customer.is_admin) ? 'Yes' : 'No'
          }}</span></div>
          <select v-model="customer.is_admin" class="box-select" v-if="showChangeAdminRole">
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          <span class="edit-setting" v-if="!showChangeAdminRole" @click="showChangeAdminRole = !showChangeAdminRole">
            <EditSetting class="edit-setting__img" />
          </span>
          <div @click="saveAdminRole" class="btn-gradient btn-save-status" v-if="showChangeAdminRole">
            Save
          </div>
        </div>

        <div class="box-card">
          <div class="box-card__title">Balance Settings</div>
          <span class="edit-setting" @click="editBalanceSettings">
            <EditSetting class="edit-setting__img" />
          </span>
        </div>
        <div class="box-card">
          <div class="box-card__title">Chat</div>
          <span class="edit-setting" @click="editChatSettings">
            <EditSetting class="edit-setting__img" />
          </span>
        </div>

        <!-- <div class="row customer-status-info">
        <div class="row customer-status-info">
          <div class="card">
            <div class="data">
              <input type="number" v-model="generalCompliance" class="form-control" max="100" min="0"
                v-if="generalComplianceIsEditing">
            </div>
            <span @click="editGeneralCompliance" >
              <Pen class="appendImg" />
            </span>
            <div @click="saveGeneralCompliance" class="btn-save-status" v-else>
              Save
            </div>
          </div>
        </div>
      </div> -->
        <div class="box-card">
          <div class="box-card__title">General Compliance: <span v-if="!generalComplianceIsEditing">{{
            generalCompliance }}</span></div>
          <input type="number" v-model="generalCompliance" class="box-input" max="100" min="0"
            v-if="generalComplianceIsEditing">

          <span class="edit-setting" @click="editGeneralCompliance" v-if="!generalComplianceIsEditing">
            <EditSetting class="edit-setting__img" />
          </span>
          <div @click="saveGeneralCompliance" class="btn-gradient btn-save-status" v-else>
            Save
          </div>
        </div>
      </div>
    </div>

    <div class="box box_my-profile box-bg-shapes">
      <div class="box-header">
        <div class="box-title">Profile</div>

        <div v-if="!editCustomerState" class="box-btn btn-edit" @click="editCustomerState = true">
          <SettingIcon style="cursor: pointer;" />
        </div>
        <div v-if="editCustomerState" class="btn-gradient btn-save-status" @click="saveCustomer">
          Save
        </div>
      </div>
      <div class="box-body">
        <div class="box-card" ref="company_full_name">
          <div class="company-img">
            <User class="box-card__img" style="width: 95px; height: 95px" />
          </div>
          <div class="box-card__title">Company <br>(full name)</div>
          <div class="box-card__input">
            <div class="box-card__input-value" v-if="!editCustomerState">{{ customer.company_full_name }}</div>
            <input name="company_full_name" class="box-input" v-if="editCustomerState" type="text"
              v-model="customer.company_full_name" placeholder="">
            <span v-show="customerFormErrors.company_full_name" class="invalid-feedback">{{
              customerFormErrors.company_full_name }}</span>
          </div>
        </div>
        <div class="box-card" ref="member_login">
          <div class="box-card__img-wrapper">
            <Lock class="box-card__img" />
          </div>
          <div class="box-card__title">Profile login</div>
          <div class="box-card__input">
            <div class="box-card__input-value" v-if="!editCustomerState">{{ customer.member_login }}</div>
            <input class="box-input" v-if="editCustomerState" type="text" v-model="customer.member_login" placeholder="">
            <span v-show="customerFormErrors.member_login" class="invalid-feedback">{{
              customerFormErrors.member_login }}</span>
          </div>
        </div>
        <div class="box-card" ref="registration_country">
          <div class="box-card__img-wrapper">
            <Worldwide class="box-card__img" />
          </div>
          <div class="box-card__title">Country of registration</div>
          <div class="box-card__input">
            <div v-if="!editCustomerState" class="box-card__input-value">{{
              customer.registration_country_name }}</div>
            <select class="box-select" v-if="editCustomerState" v-model="customer.registration_country">
              <option value="null">Select country</option>
              <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
            </select>
          </div>

        </div>
        <div class="box-card" ref="website">
          <div class="box-card__img-wrapper">
            <Worldwide class="box-card__img" />
          </div>

          <div class="box-card__title">Website</div>
          <div class="box-card__input">
            <div class="box-card__input-value" v-if="!editCustomerState">{{ customer.website }}</div>
            <input class="box-input" v-if="editCustomerState" type="text" v-model="customer.website" placeholder="">
          </div>
        </div>
        <div class="box-card" ref="company_registration_number">
          <div class="box-card__img-wrapper">
            <ShoppingList class="box-card__img" />
          </div>

          <div class="box-card__title">Company registration number</div>
          <div class="box-card__input">
            <div class="box-card__input-value" v-if="!editCustomerState">{{ customer.company_registration_number }}</div>
            <input class="box-input" v-if="editCustomerState" type="text" v-model="customer.company_registration_number"
              placeholder="">
          </div>
        </div>
        <div class="box-card" ref="email">
          <div class="box-card__img-wrapper">
            <Mail class="box-card__img" />
          </div>

          <div class="box-card__title">E-mail</div>
          <div class="box-card__input">
            <div class="box-card__input-value" v-if="!editCustomerState">{{ customer.email }}</div>
            <input class="box-input" v-if="editCustomerState" type="text" v-model="customer.email" placeholder="">
            <span v-show="customerFormErrors.email" class="invalid-feedback">{{ customerFormErrors.email }}</span>
          </div>
        </div>
        <div class="box-card" ref="mobile_phone">
          <div class="box-card__img-wrapper">
            <Telephone class="box-card__img" />
          </div>

          <div class="box-card__title">Mobile phone</div>
          <div class="box-card__input">
            <div class="box-card__input-value" v-if="!editCustomerState">{{ customer.mobile_phone }}</div>
            <input class="box-input" v-if="editCustomerState" type="text" v-model="customer.mobile_phone" placeholder="">
            <span v-show="customerFormErrors.mobile_phone" class="invalid-feedback">{{ customerFormErrors.mobile_phone
            }}</span>
          </div>
        </div>
      </div>
    </div>


    <div class="transparent-box" style="display: flex;">
      <div class="box box_b-info box-bg-shapes">
        <div class="box-header">
          <div class="box-title">Business information</div>
          <div v-if="!editBusinessSettingsState" @click="editBusinessSettingsState = true" class="box-btn btn-edit">
            <SettingIcon style="cursor: pointer;" />
          </div>
          <div v-if="editBusinessSettingsState" class="btn-gradient btn-save-status" @click="saveBusinessSettings">
            Save
          </div>
        </div>
        <div class="content">
          <div class="row" style="margin-bottom: 15px">
            <div class="box-card" ref="monthly_turnover">
              <div class="box-card__content">
                <div class="box-card__img-wrapper">
                  <Dollar class="box-card__img" />
                </div>
                <div class="box-card__title">Monthly <br> turnover ($)</div>
              </div>

              <div class="box-card__input">
                <div class="box-card__input-value" v-if="!editBusinessSettingsState">{{
                  businessSettingsForm.monthly_turnover }}</div>
                <input name="monthly_turnover" class="box-input" v-else type="text"
                  v-model="businessSettingsForm.monthly_turnover" placeholder="">
                <span v-show="businessSettingsErrors.monthly_turnover" class="invalid-feedback">{{
                  businessSettingsErrors.monthly_turnover }}</span>
              </div>
            </div>
            <div class="box-card" ref="clients_geo">
              <div class="box-card__content">
                <div class="box-card__img-wrapper">
                  <MapPin class="box-card__img" />
                </div>
                <div class="box-card__title">Geo of clients</div>
              </div>

              <div class="box-card__input">
                <div v-if="!editBusinessSettingsState" class="box-card__input-value">
                  {{ businessSettingsForm.clients_geo_name }}
                </div>
                <select class="box-select" v-else v-model="businessSettingsForm.clients_geo">
                  <option value="null">Worldwide</option>
                  <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="box-card" ref="company_country_origin">
              <div class="box-card__content">
                <div class="box-card__img-wrapper">
                  <MapPin class="box-card__img" />
                </div>
                <div class="box-card__title">Address of <br> Substance</div>
              </div>
              <div class="box-card__input">
                <div v-if="!editBusinessSettingsState" class="box-card__input-value">{{
                  businessSettingsForm.company_country_origin_name }}</div>
                <select class="box-select" v-else v-model="businessSettingsForm.company_country_origin">
                  <option value="null">Worldwide</option>
                  <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="box-card card-min" ref="worldwide_licence">
              <div class="box-card__content">
                <div class="box-card__img-wrapper">
                  <DocLicense class="box-card__img" />
                </div>
                <div class="box-card__title">License</div>
              </div>
              <div class="box-card__input">
                <div v-if="!editBusinessSettingsState" class="box-card__input-value">{{
                  businessSettingsForm.worldwide_licence_text
                }}
                </div>
                <select class="box-select" v-else v-model="businessSettingsForm.worldwide_licence">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <div class="box-card card-min" ref="worldwide_kyc">
              <div class="box-card__content">
                <div class="box-card__img-wrapper">
                  <DocLicense class="box-card__img" />
                </div>
                <div class="box-card__title">KYC</div>
              </div>

              <div class="box-card__input">
                <div v-if="!editBusinessSettingsState" class="box-card__input-value">{{
                  businessSettingsForm.worldwide_kyc_text }}
                </div>
                <select class="box-select" v-else v-model="businessSettingsForm.worldwide_kyc">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <div class="box-card" ref="ubo_residential">
              <div class="box-card__content">
                <div class="box-card__img-wrapper">
                  <MapPin class="box-card__img" />
                </div>
                <div class="box-card__title">UBO <br> residental</div>
              </div>

              <div class="box-card__input">
                <div v-if="!editBusinessSettingsState" class="box-card__input-value">{{
                  businessSettingsForm.ubo_residential_name }}
                </div>
                <select class="box-select" v-else v-model="businessSettingsForm.ubo_residential">
                  <option value="null">Worldwide</option>
                  <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="box-card card-min" ref="ubo_nominal">
              <div class="box-card__content">
                <div class="box-card__img-wrapper">
                  <BreifcaceTr class="box-card__img" />
                </div>
                <div class="box-card__title">Director nominal</div>
              </div>
              <div class="box-card__input">
                <div v-if="!editBusinessSettingsState" class="desc">{{ businessSettingsForm.ubo_nominal_text }}
                </div>
                <select class="box-select" v-else v-model="businessSettingsForm.ubo_nominal">
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box box_comp-logo">
        <div class="box-header box-header_center">
          <div class="box-title">Photo</div>
        </div>
        <div ref="logo" class="logo" v-bind:style="{ 'background-image': 'url(' + logo + ')' }">
        </div>
      </div>
    </div>

    <div>
      <div class="box box_companies box_bg-currencies">
        <div class="box-header box-header_center">
          <div class="box-title">
            <div class="box-title__img">
              <BreifcaceTr />
            </div>
            Companies
          </div>
        </div>
        <div v-if="hasCompanies" class="content">

          <div class="row page-clients__table-wrap main-table">
            <!-- <Table :options="standardCompanies" @edit="editCompany" /> -->
            <DataTable :value="standardCompanies" dataKey="id" tableClass="companies-box-table" paginator :rows="2">
              <Column field="id" header="Id"></Column>
              <Column field="name" header="Name"></Column>
              <Column field="email" header="Email"></Column>
              <Column field="phone" header="phone"></Column>
            </DataTable>
          </div>
          <div class="row page-clients__table-wrap main-table">
            <!-- <div class="tbl-title">Incorporation companies</div> -->
            <!-- <Table :options="incorporationCompanies" @edit="editCompany" /> -->
            <DataTable :value="incorporationCompanies" dataKey="id" tableClass="companies-box-table" paginator :rows="2">
              <Column field="id" header="Id"></Column>
              <Column field="name" header="Name"></Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="content-empty">
          <span>
            Customer has no companies
          </span>
        </div>
      </div>
      <!-- <div class="box box_referral">
        <div class="box-header box-header_center">
          <div class="box-title">
            <div class="box-title__img">
              <Infinite />
            </div>
            Referral Link:
          </div>
        </div>
        <div class="referral-input">
          <a class="referral-link" :href="landingUrl + '?referral_code=' + customer.referral_code">
            {{ landingUrl + '?referral_code=' + customer.referral_code }}
          </a>
          <div @click="copyCode" class="referral-input__btn">Copy</div>
          <input type="hidden" id="referral-link" :value="landingUrl + '?referral_code=' + customer.referral_code">
        </div>
      </div> -->
    </div>

    <!-- <div class="box box_bg-currencies" v-if="customer.is_provider">
      <ProvidersReferrals :provider="customer" />
      <LegalAtlases :provider="customer" />
      <div class="provider-info-tables">
        <ProviderAccounts :provider="customer" />
        <AdditionalServices :provider="customer" />
        <ProviderBusinesOffers :provider="customer" />
      </div>
    </div>
    <div v-else>
      <CustomerReferrals :customer="customer" />
    </div> -->

    <div class="box box_notifications box_bg-currencies">
      <div class="box-header header-blur box-header_center">
        <div class="box-title">
          Notifications
        </div>
      </div>

      <div class="noti-wrapper">
        <div class="noti" v-for="notification in notifications">
          <div class="noti__avatar" v-bind:style="{ 'background-image': 'url(' + logo + ')' }"></div>
          <div class="noti__text">{{ notification.text }}</div>
        </div>
      </div>
    </div>

  </overlay-scrollbars>
</template>

<script type="text/javascript">
import { mapState, mapGetters, mapActions } from 'vuex';

import Stat from './../components/img/Stat.vue'
import Pen from './../components/img/Pen.vue'
import User from './../components/img/User.vue'
import Lock from './../components/img/Lock.vue'
import Worldwide from './../components/img/Worldwide.vue'
import BrowserWindow from './../components/img/BrowserWindow.vue'
import ShoppingList from './../components/img/ShoppingList.vue'
import Mail from './../components/img/Mail.vue'
import Telephone from './../components/img/Telephone.vue'
import Dollar from './../components/img/Dollar.vue'
import MapPin from './../components/img/MapPin.vue'
import DocLicense from './../components/img/DocLicense.vue'
import BreifcaceTr from './../components/img/BreifcaceTr.vue'
import Exchange from './../components/img/Exchange.vue'
import Close from './../components/img/Close.vue'
import { Compact } from 'vue-color'
import Settings from './../components/img/Settings.vue'
import Table from './../components/elem/Table.vue'
import Pagination from 'laravel-vue-pagination'
import NotificationText from '@/mixins/notifications_text.js'
import LegalAtlases from './../components/provider/LegalAtlases.vue'
import ProviderAccounts from './../components/provider/ProviderAccounts.vue'
import AdditionalServices from './../components/provider/AdditionalServices.vue'
import ProviderBusinesOffers from './../components/provider/BusinesOffers.vue'
import ProvidersReferrals from './../components/provider/ProvidersReferrals.vue'
import CustomerReferrals from './../components/customer/CustomerReferrals.vue'
import SettingIcon from '../components/icons/SettingIcon.vue';
import EditSetting from '../components/icons/EditSetting.vue';
import Infinite from '../components/img/Infinite.vue';

export default {
  mixins: [
    NotificationText
  ],
  components: {
    Stat,
    Pen,
    User,
    Lock,
    Worldwide,
    BrowserWindow,
    ShoppingList,
    Mail,
    Telephone,
    Dollar,
    MapPin,
    DocLicense,
    BreifcaceTr,
    Exchange,
    Close,
    'compact-picker': Compact,
    Settings,
    Pagination,
    Table,
    LegalAtlases,
    ProviderAccounts,
    AdditionalServices,
    ProviderBusinesOffers,
    ProvidersReferrals,
    CustomerReferrals,
    SettingIcon,
    EditSetting,
    Infinite
  },
  data: () => ({
    ratingColor: {
      hex: '#ffffff'
    },
    ratingPallete: [],
    customer: {},
    businessSettingsForm: {},
    customerRequestIsNew: false,
    showStatusInput: false,
    showChangeAdminRole: false,
    status: false,
    showPaidStatusText: false,
    showRatingText: false,
    showRiskInput: false,
    showRatingInput: false,
    showKybRateManual: false,
    isKybRateManual: false,
    riskId: null,
    showRiskStatusText: false,
    risks: [],
    logo: '',
    editCustomerState: false,
    editBusinessSettingsState: false,
    notificationTableKey: 0,
    generalComplianceIsEditing: false,
    generalCompliance: 0,
    notifications: null,
    searchDelayTimer: null,
    customerFormErrors: {
      company_full_name: null,
      member_login: null,
      registration_country: null,
      company_registration_number: null,
      email: null,
      mobile_phone: null,
    },
    businessSettingsErrors: {
      clients_geo: null,
      company_country_origin: null,
      monthly_turnover: '',
      ubo_nominal: false,
      ubo_residential: null,
      user_id: null,
      worldwide_kyc: false,
      worldwide_licence: false,
    },
    landingUrl: process.env.VUE_APP_LANDING_URL,
    registrationCountry: 0,
  }),
  created() {
    // this.changeMeta()
    this.getCustomer()
    this.getRisks()
    this.setCountries()
    this.setIndustries()
    this.setLicenseTypes()
    this.getRatingColors()
    this.getNotifications()
  },
  watch: {
    viewUpdateProfileFields(notification) {
      if (notification) {
        this.showUpdatedProfilePage(notification.notification.data.updated_fields)
      }
    },
    viewUpdatedBusinessInformationFields(notification) {
      if (notification) {
        this.showUpdatedProfilePage(notification.notification.data.updated_fields)
      }
    },
    viewUpdatedLogo(notification) {
      if (notification) {
        this.goToLogo()
      }
    },
    viewUpdatedRequest() {
      this.showRequestModal()
    },
    applyOffer(notification) {
      this.showAppliedOfferModal()
    },
    cancelOffer(notification) {
      this.showAppliedOfferModal()
    },
    updateCompliance(notification) {
      this.showUploadedFilesModal()
    },
    'customer.companies': function (nw) {
      if (nw.length && this.$route.params.company_id) {
        this.editCompany(this.$route.params.company_id)
      }
    }
  },
  computed: {
    ...mapState({
      countries: state => state.countries,
      industries: state => state.industries,
      licenseTypes: state => state.licenseTypes,
    }),
    ...mapGetters({
      viewUpdateProfileFields: 'notifications/viewUpdateProfileFields',
      viewUpdatedBusinessInformationFields: 'notifications/viewUpdatedBusinessInformationFields',
      viewUpdatedLogo: 'notifications/viewUpdatedLogo',
      viewUpdatedRequest: 'notifications/updateRequest',
      applyOffer: 'notifications/applyOffer',
      cancelOffer: 'notifications/cancelOffer',
      updateCompliance: 'notifications/updateCompliance',
    }),
    hasCompanies() {
      return (this.customer && this.customer.companies && this.customer.companies.length)
    },
    standardCompanies() {
      const companies = this.hasCompanies ? this.customer.companies.filter(el => el.type_id === 1) : [];
      return companies.map(el => ({
        id: el.id.toString(),
        name: el.name,
        email: el.email,
        phone: el.phone,
      }));
    },
    incorporationCompanies() {
      const companies = this.hasCompanies ? this.customer.companies.filter(el => el.type_id === 2) : [];
      return companies.map(el => ({
        id: el.id.toString(),
        name: el.name,
      }));
    },
  },
  mounted() {
    if (this.$route.params) {
      if (this.$route.params.viewUpdateProfileFields) {
        this.showUpdatedProfilePage(this.$route.params.viewUpdateProfileFields)
      }
      if (this.$route.params.viewUpdatedBusinessInformationFields) {
        this.showUpdatedProfilePage(this.$route.params.viewUpdatedBusinessInformationFields)
      }
      if (this.$route.params.viewUpdatedLogo) {
        this.goToLogo()
      }
      if (this.$route.params.viewUpdatedRequest) {
        this.showRequestModal()
      }
      if (this.$route.params.viewAppliedOffers) {
        this.showAppliedOfferModal()
      }
      if (this.$route.params.viewCompliance) {
        this.showUploadedFilesModal()
      }
    }
  },
  methods: {
    ...mapActions({
      setCountries: 'setCountries',
      setIndustries: 'setIndustries',
      setLicenseTypes: 'setLicenseTypes',
    }),
    editGeneralCompliance() {
      this.generalComplianceIsEditing = true;
    },
    async saveGeneralCompliance() {
      await axios.post('admin/customers/' + this.$route.params.id, {
        email: this.customer.email,
        general_compliance: this.generalCompliance
      })
        .then(res => {
          console.log(res)
        })
        .catch(error => {
          console.log(error);
        });

      this.generalComplianceIsEditing = false
    },
    onRatingChange() {
      if (this.customer.rating < 0) this.customer.rating = 0;
      if (this.customer.rating > 100) this.customer.rating = 100;
    },
    // changeMeta() {
    //   if (this.$route.params.isProvider) {
    //     this.$route.meta.headerName = 'Legal Provider'
    //     this.$route.meta.breadcrumbs = [
    //       {
    //         type: 'link',
    //         text: 'Legal Provider',
    //         routeName: 'LegalProvider'
    //       },
    //       {
    //         text: 'Legal Provider Details'
    //       }
    //     ]
    //   } else if (this.$route.params.isAdmin) {
    //     this.$route.meta.headerName = 'Administrator'
    //     this.$route.meta.breadcrumbs = [
    //       {
    //         type: 'link',
    //         text: 'Administrators',
    //         routeName: 'Admins'
    //       },
    //       {
    //         text: 'Administrator Details'
    //       }
    //     ]
    //   } else {
    //     this.$route.meta.headerName = 'Customer'
    //     this.$route.meta.breadcrumbs = [
    //       {
    //         type: 'link',
    //         text: 'Customer',
    //         routeName: 'Customer'
    //       },
    //       {
    //         text: 'Customer Details'
    //       }
    //     ]
    //   }
    // },
    toNotificationsTop(el) {
      this.$refs.notifications.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
    toogleKybRateManual() {
      this.isKybRateManual = !this.isKybRateManual
    },
    toogleShowCalculatedRisk() {
      if (+this.customer.show_calculated_risk == 1) {
        this.customer.show_calculated_risk = 0
      } else {
        this.customer.show_calculated_risk = 1
      }
    },
    saveKybRateManual() {
      const that = this
      axios.post('admin/customers/toogle-kyb-rate-manual/' + this.$route.params.id, {
        is_kyb_rate_manual: this.isKybRateManual,
        kyb_rate_manual: this.customer.kyb_rate_manual,
        kyb_rate_comment: this.customer.kyb_rate_comment,
      })
        .then(function (response) {
          that.showKybRateManual = false
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    goToLogo() {
      const element = this.$refs.logo
      if (element) {
        element.scrollIntoView(false)
      }
    },
    showUpdatedProfilePage(updatedFields) {
      if (updatedFields && Object.keys(updatedFields)[0]) {
        const key = Object.keys(updatedFields)[0]
        const element = this.$refs[key]
        if (element) {
          element.scrollIntoView()
        }
      }
    },
    getCustomer() {
      axios.get('admin/customers/show/' + this.$route.params.id)
        .then(response => {
          this.ratingColor.hex = (response.data.rating_color) ? response.data.rating_color : '#ffffff'
          response.data.rating = (response.data.rating) ? response.data.rating : ''
          this.customer = response.data
          this.customer.calculatedRiskName = (typeof this.customer.calculatedRisk === 'object' && this.customer.calculatedRisk !== null)
            ? this.customer.calculatedRisk.name
            : ''

          if (this.customer.payment_request_settings_changed) {
            this.customerRequestIsNew = true
          }
          this.status = (this.customer.is_paid === 1) ? true : false
          this.showPaidStatusText = true
          this.showRatingText = true
          this.riskId = this.customer.risk_id
          this.showRiskStatusText = true;
          this.generalCompliance = this.customer.general_compliance;
          this.businessSettingsForm = this.customer.business_settings ? this.customer.business_settings : {}
          this.logo = this.customer.logo
          this.isKybRateManual = (this.customer.is_kyb_rate_manual == '1') ? true : false
        })
        .catch(error => console.log(error))
    },
    saveStatus() {
      const that = this
      axios.post('admin/customers/change-paid-status/' + this.$route.params.id, {
        status: this.status
      })
        .then(function (response) {
          if (response.data.success) {
            that.customer.is_paid = response.data.is_paid
            that.showStatusInput = false
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    saveAdminRole() {
      const that = this
      axios.post('admin/change-admin-role/' + this.$route.params.id, {
        is_admin: this.customer.is_admin
      })
        .then(function (response) {
          if (response.data.success) {
            that.customer.is_admin = response.data.is_admin
          }
          that.showChangeAdminRole = false
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getRisks() {
      const that = this
      axios.get('admin/data/risks')
        .then(function (response) {
          that.risks = response.data
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    saveRisk() {
      const that = this
      axios.post('admin/customers/change-risk/' + this.$route.params.id, {
        risk_id: this.riskId,
        show_calculated_risk: this.customer.show_calculated_risk,
      })
        .then(function (response) {
          if (response.data.success) {
            that.customer.risk = response.data.risk
            that.customer.risk_id = response.data.risk_id
            that.customer.show_calculated_risk = response.data.show_calculated_risk
            that.showRiskInput = false
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    saveRating() {
      const that = this
      axios.post('admin/customers/update-rating/' + this.$route.params.id, {
        rating_color: this.ratingColor.hex,
        rating: this.customer.rating
      })
        .then(function (response) {
          if (response.data.success) {
            that.customer.rating_color = response.data.rating_color
            that.customer.rating = response.data.rating
            that.showRatingInput = false
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    getRatingColors() {
      const that = this
      axios.get('admin/data/rating-colors')
        .then(function (response) {
          for (let i = 0; i < response.data.length; i++) {
            that.ratingPallete.push(response.data[i].code)
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    clearCustomerFormErrors() {
      for (let key in this.customer) {
        this.customerFormErrors[key] = null
      }
    },
    saveCustomer() {
      this.clearCustomerFormErrors()
      axios.post('admin/customers/' + this.$route.params.id, { ...this.customer, logo: null })
        .then(response => {
          if (response.data.error) {
            for (let key in response.data.errors) {
              if (key === 'company_full_name' && response.data.errors[key][0]) {
                this.customerFormErrors.company_full_name = response.data.errors[key][0]
              } else if (key === 'email' && response.data.errors[key][0]) {
                this.customerFormErrors.email = response.data.errors[key][0]
              } else if (key === 'member_login' && response.data.errors[key][0]) {
                this.customerFormErrors.member_login = response.data.errors[key][0]
              } else if (key === 'mobile_phone' && response.data.errors[key][0]) {
                this.customerFormErrors.mobile_phone = response.data.errors[key][0]
              }
            }

            return
          }
          if (response.data.success) {
            if (response.data.customer) {
              this.customer.registration_country = response.data.customer.registration_country
              this.customer.registration_country_name = response.data.customer.registration_country_name
              this.registrationCountry++
            }
            this.clearCustomerFormErrors()
            this.editCustomerState = false
          }
        })
        .catch(error => {
          console.dir(error);
        })
    },
    clearBusinessSettingsErrors() {
      for (let key in this.businessSettingsErrors) {
        this.businessSettingsErrors[key] = null
      }
    },
    saveBusinessSettings() {
      this.clearBusinessSettingsErrors()
      const that = this
      axios.post('admin/customers/business-settings/' + this.$route.params.id, this.businessSettingsForm)
        .then((response) => {
          if (response.data.error) {
            for (let key in response.data.errors) {
              if (key === 'monthly_turnover' && response.data.errors[key][0] && typeof that.businessSettingsErrors === 'object') {
                that.businessSettingsErrors.monthly_turnover = response.data.errors[key][0]
              }
            }
            return
          }
          that.businessSettingsForm.ubo_residential_name = response.data.data.ubo_residential_name
          that.businessSettingsForm.clients_geo_name = response.data.data.clients_geo_name
          that.businessSettingsForm.company_country_origin_name = response.data.data.company_country_origin_name

          that.businessSettingsForm.ubo_nominal = response.data.data.ubo_nominal
          that.businessSettingsForm.ubo_nominal_text = response.data.data.ubo_nominal_text
          that.businessSettingsForm.worldwide_kyc = response.data.data.worldwide_kyc
          that.businessSettingsForm.worldwide_kyc_text = response.data.data.worldwide_kyc_text
          that.businessSettingsForm.worldwide_licence = response.data.data.worldwide_licence
          that.businessSettingsForm.worldwide_licence_text = response.data.data.worldwide_licence_text
          that.editBusinessSettingsState = false
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    showRequestModal() {
      this.$store.commit('setModal', {
        template: 'request',
        options: {
          id: this.$route.params.id,
          type: 'customer',
          url: 'admin/customers/' + this.$route.params.id,
          successfullMessage: 'You successfully deleted customer',
        }
      })
    },
    showCreateIndivualOfferModal() {
      this.$store.commit('setModal', {
        template: 'create-individual-offer',
        options: {
          id: this.$route.params.id,
        }
      })
    },
    showUploadedFilesModal() {
      this.$store.commit('setModal', {
        template: 'uploaded-files',
        options: {
          id: this.$route.params.id,
          type: 'customer',
          url: 'admin/customers/' + this.$route.params.id,
          successfullMessage: 'You successfully deleted customer',
        }
      })
    },
    showAppliedOfferModal() {
      this.$store.commit('setModal', {
        template: 'applied-offer',
        options: {
          id: this.$route.params.id,
          type: 'customer',
          url: 'admin/customers/' + this.$route.params.id,
          successfullMessage: 'You successfully deleted customer',
        }
      })
    },
    showDownloadInvoiceModal() {
      this.$store.commit('setModal', {
        template: 'download-invoice',
        options: {
          id: this.$route.params.id,
          type: 'customer',
          url: 'admin/customers/' + this.$route.params.id,
          successfullMessage: 'You successfully deleted customer',
        }
      })
    },
    changePassword() {
      this.$store.commit('setModal', {
        template: 'change-password',
        options: {
          customerId: this.$route.params.id
        }
      })
    },
    editBalanceSettings() {
      this.$store.commit('setModal', {
        template: 'balance-settings',
        options: {
          customerId: this.$route.params.id
        }
      })
    },
    editChatSettings() {
      this.$store.commit('setModal', {
        template: 'common-chat-settings',
        options: {
          customerId: this.$route.params.id
        }
      })
    },
    getNotifications(page = 1) {
      axios.get('admin/notifications?page=' + page + '&user_id=' + this.$route.params.id)
        .then(res => {
          this.notifications = []
          res.data.data.forEach(el => {
            const text = this.textNotification(el);

            console.log(el)

            this.notifications.push({
              type: 'notification',
              text: text,
              notificationType: el.notification.data.type,
              notification: el
            })
          })
        })
        .catch((errors) => {
          console.dir(errors)
        })
    },
    editCompany(id) {
      const data = this.customer.companies.find(el => el.id === +id);

      this.$store.commit('setModal', {
        template: 'edit-user-company',
        options: {
          company: data,
          countries: this.countries,
          industries: this.industries,
          licenseTypes: this.licenseTypes,
          fetchData: this.getCustomer,
        },
      })

      if (this.$route.params.company_id) {
        delete this.$route.params.company_id
      }
    },
    copyCode() {
      let codeToCopy = document.querySelector('#referral-link')
      codeToCopy.setAttribute('type', 'text')
      codeToCopy.select()
      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        this.$noty.success('Link was copied ' + msg);
      } catch (err) {
        this.$noty.error('Oops, unable to copy');
      }
      codeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
  }
}
</script>

<style lang="scss" scoped>
.box {
  &_notifications {
    .noti-wrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 20px;
      gap: 10px;
    }

    .noti {
      &__avatar {
        margin-right: 10px;
        width: 40px;
        height: 40px;
        background-size: cover;
        border-radius: 50%;
      }

      &__text {
        font-weight: bold;
      }

      display: flex;
      align-items: center;
      padding: 10px;
      width: 49%;
      background: linear-gradient(180deg, #4FE15D 0%, #14935D 100%);
      border-radius: 30px;
    }
  }

  &_settings {
    background-image: url('/img/customer-settings-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 50px;

    .box-body {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      grid-template-areas:
        "col1 col2 col3 col4";

      .box-card {
        min-height: 100px;
        background: linear-gradient(180deg, #4FE15D 0%, #14935D 100%);
        flex-direction: row;
        align-items: center;
        padding: 0 20px;

        .edit-setting {
          position: initial;
          background: var(--c_black);
        }
      }
    }
  }

  &_comp-logo {
    background-image: url('/img/currencies-bg.png');
    background-repeat: no-repeat;
    background-size: 300%;
    background-position: center;
  }

  &_balance {
    .box-body {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      grid-template-areas:
        "col1 col2 col3 col4";

      .box-card {
        &__title {
          margin-top: 30px;
        }

        &__bg {
          position: absolute;
          z-index: -1;
          bottom: 0;
        }

        background: rgb(0, 0, 0);
        background: radial-gradient(circle, rgba(0, 0, 0, 0.637) 15%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }

  &_my-profile {
    .box-body {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      grid-template-areas:
        "col1 col2 col4 col6"
        "col1 col3 col5 col7";
    }
  }

  &_b-info {
    margin-right: 2rem;

    .box-card__content {
      display: flex;
      align-items: center;
      padding: 10px 15px;
    }

    .box-card {
      min-height: initial;
      width: 32%;
      justify-content: space-between;
      overflow: hidden;


      &__input {
        margin: 0;
      }

      &__img-wrapper {
        margin: 0 15px 0 0;
      }

      &__title {
        text-align: initial;
      }
    }

    .content {
      padding: 3.5vh 0;

      .row {
        display: flex;
        justify-content: space-between;

        .card {
          border: 1px solid #1C31F0;
          width: 30%;

          &-min {
            width: 20%;
          }
        }
      }
    }
  }

  &_companies {
    // width: 50%; //with referrals
    width: 100%;
  }

  &_referral {
    .box-header {
      background: rgba(255, 255, 255, 0.1);
    }

    width: 50%;
    max-height: 250px;
    background: linear-gradient(180deg, #4FE15D 0%, #14935D 100%);
  }

  &-card {
    &:nth-child(1) {
      grid-area: col1
    }

    &:nth-child(2) {
      grid-area: col2
    }

    &:nth-child(3) {
      grid-area: col3
    }

    &:nth-child(4) {
      grid-area: col4
    }

    &:nth-child(5) {
      grid-area: col5
    }

    &:nth-child(6) {
      grid-area: col6
    }

    &:nth-child(7) {
      grid-area: col7
    }

    .company-img {
      padding: 15px;
      border-radius: 50%;
      margin-top: 50px;
      margin-bottom: 15px;
      background: rgb(255, 255, 255);
      background: linear-gradient(127deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8044467787114846) 100%);
    }
  }

  &_rate {
    position: relative;
    height: 7rem;
    height: 11vh;
    margin-top: 6rem;
    margin-top: 7vh;
    margin-bottom: 4rem;
    margin-bottom: 6vh;

    .box-inside {
      width: 95%;
      height: 11rem;
      height: calc(100% + 6vh);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: var(--gr_new);
      box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
      display: flex;

      .content {
        flex-grow: 1;

        .scale-box {
          width: 100%;
        }
      }

      .img {
        height: 14rem;
        height: calc(100% + 6vh);
        position: relative;
        top: 100%;
        transform: translateY(-96%);
        margin: auto 3rem;
        margin: auto 3vw;
      }
    }
  }

  &_comp-logo {
    width: 23rem;
    position: relative;
    background-color: #272E35;
    overflow: hidden;
    padding-bottom: 1rem;

    .dots {
      position: absolute;

      &-top {
        top: -5px;
        right: 7px;
        width: 2rem;
        opacity: .5;
      }

      &-left {
        top: 6.5rem;
        left: 28px;
        width: 1.5rem;
        opacity: .5;
      }

      &-bottom {
        bottom: -12px;
        right: 1.75rem;
        width: 2.5rem;
        transform: rotate(90deg);
        opacity: 0.5;
      }
    }

    .header {
      .title {
        &::before {
          background-color: rgb(255 255 255 / 25%);
        }
      }
    }

    .logo {
      flex-grow: 1;
      width: 14rem;
      height: 14rem;
      margin: .25rem auto;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      position: relative;
      z-index: 1;

      .btn {
        width: 3.25rem;
        height: 3.25rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &_update {
          background-color: #1C31F0;

          svg {
            width: 1.75rem;
            height: 1.75rem;
          }
        }

        &_remove {
          background-color: #B03636;

          svg {
            width: 1.25rem;
            height: 1.25rem;
          }
        }
      }
    }
  }
}

.invalid-feedback {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.transparent-box {
  width: 100%;
  border-radius: 14px;
  margin: 1rem 0;
}

// .rating-color {
//   width: 30px;
//   height: 30px;
//   border-radius: 50%;
// }


.content-empty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    background-color: rgba(255, 255, 255, 0.3);
    display: inline-block;
    padding: 8px 20px;
    border-radius: 30px;
    font-weight: bold;
    margin: 20px 0 40px 0;
  }
}


.tmblr {
  display: flex;
  align-items: center;
  padding: 0 .25rem;

  .txt {
    color: #ffffff;
  }

  .btn {
    &-tmblr {
      position: relative;
      width: 1.5rem;
      height: .5rem;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 2rem;
      margin: auto 1rem;

      &-l::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #ffffff;
      }

      &-r::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #ffffff;
      }
    }
  }

  &-hide {
    .txt {
      color: rgba(255, 255, 255, 0.4);
    }

    .btn-tmblr-r::before {
      background-color: #1C31F0;
    }
  }
}


.kyb-rate-comment {
  margin-top: 10px;
}

.notifications_tbl {
  width: 100%;
  margin-top: 20px;
}

.risk-form-control {
  margin-bottom: 0px;
  margin-left: 10px;
}

.referral-input {
  margin: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 24px -1px #00000033;

  .referral-link {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    padding: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__btn {
    background: linear-gradient(90.86deg, #1C31F0 -2.12%, #931CF0 87.62%);
    font-weight: bold;
    font-size: 18px;
    padding: 12px 40px;
    border-radius: 30px;
    cursor: pointer;
  }
}

.customer-detail-info{
  display: flex;
}

.show-modal-btn{
  margin-right: 15px;
}
</style>
